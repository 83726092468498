import { User } from "./user";

export interface ActionLog {
	_id			:		number; /** Id of the ActionLog */

	action?		:		string | null;

	description?:		string | null;

	idUser?		:		string | null; /** Id of the user acting */

	creationDate	:	Date /** Date of creation */
	
	/** FIELD RESOLVER */
	user			:	User | null;
}

export interface ActionLogFilters {
	_ids?			:		number[] | null;

	actions?		:		string[] | null;

	idUsers?		:		string[] | null
}

export enum ActionLogType {
	LOGIN = "LOGIN",
	LOGOUT = "LOGOUT",

	CREATE_RECORD = "CREATE_RECORD",
	UPDATE_RECORD = "UPDATE_RECORD",
	REJECT_RECORD = "REJECT_RECORD",
	DELETE_RECORD = "DELETE_RECORD",
	
	CREATE_USER = "CREATE_USER",
	UPDATE_USER = "UPDATE_USER",
	DELETE_USER = "DELETE_USER",
	ENABLE_USER = "ENABLE_USER",
	DISABLE_USER = "DISABLE_USER",
	
	CREATE_HOSPITAL = "CREATE_HOSPITAL",
	DELETE_HOSPITAL = "DELETE_HOSPITAL",
	UPDATE_HOSPITAL = "UPDATE_HOSPITAL",

	DOWNLOAD_ORIGINAL_IRM = "DOWNLOAD_ORIGINAL_IRM",
	DOWNLOAD_TARGETED_IRM = "DOWNLOAD_TARGETED_IRM",
	UPLOAD_TARGETED_IRM = "UPLOAD_TARGETED_IRM",
	UPLOAD_ORIGINAL_IRM = "UPLOAD_ORIGINAL_IRM",

	DOWNLOAD_POSTOP_IMAGE = "DOWNLOAD_POSTOP_IMAGE",
	DOWNLOAD_POSTOP_CHECK = "DOWNLOAD_POSTOP_CHECK",
	UPLOAD_POSTOP_CHECK = "UPLOAD_POSTOP_CHECK",
	UPLOAD_POSTOP_IMAGE = "UPLOAD_POSTOP_IMAGE",
}