// import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient, InMemoryCache } from '@apollo/client';
// import { createHttpLink } from "apollo-link-http";
import { createUploadLink } from 'apollo-upload-client';

console.log(`Apollo server ${process.env.REACT_APP_SERVER}`)
const httpLink = createUploadLink({
	uri: `${process.env.REACT_APP_SERVER}`,
	credentials: 'include',
	headers: {
		'Apollo-Require-Preflight': 'true'
	}
	
})

export const Client = new ApolloClient({
	link: httpLink,
	cache: new InMemoryCache({
		addTypename: false
	}),
	defaultOptions: {
		query: {fetchPolicy: 'no-cache',},
		watchQuery: {fetchPolicy: 'no-cache',}
	}
});

export * from './HospitalCenterRequests';
export * from './PatientRecordRequests';
export * from './UserRequests';
export * from './ServerRequests';
