import { withApollo, WithApolloClient } from '@apollo/react-hoc'
import {
    Box,
    Container,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { GraphQLError } from 'graphql'
import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { HospitalCenterRequests } from '../../../apollo'
import ConfirmationDialog, { ConfirmationInformation } from '../../../components/confirmationDialog'
import ErrorDialog, { IErrorInformation } from '../../../components/errorDialog'
import InformativeDialog, { IInformative } from '../../../components/informativeDialog'
import { HospitalCenter } from '../../../rebrain-irm-model'
import { UpdateHospitalCenterRouter } from '../../../router/dashboard/updateHospitalCenter/updateHospitalCenter.router'
import HospitalRow from './hospitalRow'

const styles = (theme: Theme) => createStyles({
	table: {
		[theme.breakpoints.up('md')]: {
			maxHeight: '90vh'
		},
	},
})

interface IState {
	hospitals: Array<HospitalCenter>

	error?: IErrorInformation;
	information?: IInformative;
	confirmation?: ConfirmationInformation;
}

interface IProps
	extends
	WithStyles<typeof styles>
	, RouteComponentProps
	, WithTranslation
	, WithApolloClient<{}>
{

}

class HospitalCentersPage extends React.Component<IProps, IState> {

	constructor(props: IProps) {
		super(props);
		this.state = {
			hospitals: []
		}
	}

	componentDidMount = () => {
		this.__loadHospitalCenters()
	}

	render = () => {
		const classes = this.props.classes;

		return(
			<Container component={Box} mt={2}>
					<TableContainer component={Paper} className={classes.table}>
						<Table stickyHeader size="small">
						<TableHead>
							<TableRow>
								<TableCell align="left" >{this.props.t('name')}</TableCell>
								<TableCell>{this.props.t('address')}</TableCell>
								<TableCell align="right"/>
							</TableRow>
						</TableHead>
						<TableBody>

						{
							this.state.hospitals.map((hospital: HospitalCenter, index) => {
								return (
									<HospitalRow
										key={index}
										onClickRemoveHospital={this.__onClickRemoveHospital}
										onClickUpdateHospital={this.__onClickUpdateHospital}
										hospital={hospital}/>
								)
							})
						}
						</TableBody>
						</Table>
					</TableContainer>
				
				<ErrorDialog error={this.state.error} onClose={() => this.setState({error: undefined})}/>
				<InformativeDialog information={this.state.information} onClose={() => {}}/>
				<ConfirmationDialog confirmation={this.state.confirmation} />
			</Container>
		)
	}

	__loadHospitalCenters = () => {
		this.setState({information: {
			title: this.props.t('loading'),
			showProgress: true
		}})
		HospitalCenterRequests.hospitalCenters("no-cache")
		.then((hospitalCenters: Array<HospitalCenter>) => {
			this.setState({hospitals: hospitalCenters})
		}).catch((err: GraphQLError) => {
			this.setState({error: {
				title: this.props.t('error'),
				message: err.message
			}})
		}).finally(() => this.setState({information: undefined}))
	}

	__onClickUpdateHospital = (hospital: HospitalCenter) => {
		this.props.history.push(UpdateHospitalCenterRouter.getRoute(hospital._id))
	}

	__onClickRemoveHospital = (hospital: HospitalCenter) => {
		this.setState({
			confirmation: {
				title: this.props.t('delete')
				, message: this.props.t('are you sure to delete this hospital center ?')
				, onClickAccept: () => this.setState({confirmation: undefined}, () => this.__onAccepToRemoveHospital(hospital))
				, onClickCancel: () => this.setState({confirmation: undefined})
			}
		})
	}

	__onAccepToRemoveHospital = (hospital: HospitalCenter) => {
		this.setState({information: {
			title: this.props.t('deleting'),
			showProgress: true
		}})
		HospitalCenterRequests.removeHospitalCenter(hospital._id)
		.then(() => {
			this.setState({information: undefined}, this.__loadHospitalCenters)
		}).catch((err: GraphQLError) => {
			this.setState({information: undefined, error: {
				title: this.props.t('error'),
				message: err.message
			}})
		})
	}

}



export default withTranslation() (withApollo(withRouter(withStyles(styles)(HospitalCentersPage)) as any))