import { withApollo, WithApolloClient } from '@apollo/react-hoc'
import { CircularProgress, Grid, Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { GraphQLError } from 'graphql'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import * as pkg from '../../../package.json'
import {UserRequests} from '../../apollo'
import Background from '../../assets/login-background.jpg'
import ErrorDialog, { IErrorInformation } from '../../components/errorDialog'
import { DashboardRouter } from '../../router/dashboard/dashboard.router'
import LoginForm from './LoginForm'

const styles = ({palette, spacing}: Theme) => createStyles({
	div: {
		backgroundImage: `url(${Background})`,
		height: '100vh',
		width: '100%',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		flexDirection: 'column-reverse'
	},
	grid: {
		height: '100%'
	},
	paper: {
		position: 'fixed',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
	form : {

	},
	progress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	version: {
		color: '#ffffff',
		fontWeight: 'bold',
		padding: 10
	}
})

interface IState {
	error?	:	IErrorInformation;

	progress:	boolean;
}

interface IProps
	extends
	WithStyles<typeof styles>
	, RouteComponentProps
	, WithTranslation
	, WithApolloClient<{}>
{

}

class LoginPage extends React.Component<IProps, IState> {

	constructor(props: IProps) {
		super(props);
		this.state = {
			progress: false
		}
	}

	componentDidMount = () => {
		this.__loadUserData()
	}

	render = () => {
		const classes = this.props.classes;

		return (
            <div className={classes.div}>
				<Grid className={classes.version}>
					v {pkg.version}
				</Grid>
				<Grid
					
					container
					justifyContent="center"
					className={classes.grid}
					>
					<Grid
						item
						direction="column"
						justifyContent="center"
						alignItems="stretch"
						container
						xs={10}
						md={4}>
							<LoginForm
								onClickForgottenPassword={this.__onClickForgottenPassword}
								onClickConnection={this.__onClickConnection}
								/>
					</Grid>
					<ErrorDialog error={this.state.error} onClose={() => this.setState({error: undefined})} />
					{this.state.progress && <CircularProgress className={classes.progress}/>}
				</Grid>
					
			</div>
        );
	}

	__onClickConnection = async (mail: string, password: string) => {
		this.setState({progress: true})

		UserRequests.login({
			email: mail,
			password: password
		}).then((user) => {
			UserRequests.meBasic("no-cache").then(() => {}).catch(() => {}).finally(() => {
				this.props.history.push(DashboardRouter.getRoute());
			})

		}).catch((err: GraphQLError) => {
			this.setState({error: {
				title: this.props.t('error'),
				message: err.message
			}})
		}).finally(() => this.setState({progress: false}))
	}

	__onClickForgottenPassword = async (mail: string) => {
		this.props.history.push(`/forgotten-password?email=${mail}`);
	}

	__loadUserData = () => {
		// UserRequests.meBasic().then(() => this.props.history.push("/dashboard")).catch(() => {});
	}
}



export default withTranslation()(withApollo(withRouter(withStyles(styles)(LoginPage)) as any));