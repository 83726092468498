import React from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { Alert, Button, Fade, Grow, IconButton, Slide, SlideProps, Typography } from '@mui/material';
import MuiSnackbar from '@mui/material/Snackbar';

// assets
import CloseIcon from '@mui/icons-material/Close';

import { DefaultRootStateProps, KeyedObject } from '../../types';
import { FormattedMessage } from 'react-intl';
import useSnackbar from '../../hooks/useSnackbar';
import { useTranslation } from 'react-i18next';

// animation function
function TransitionSlideLeft(props: SlideProps) {
	return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props: SlideProps) {
	return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props: SlideProps) {
	return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props: SlideProps) {
	return <Slide {...props} direction="down" />;
}

function GrowTransition(props: SlideProps) {
	return <Grow {...props} />;
}

// animation options
const transition: KeyedObject = {
	SlideLeft: TransitionSlideLeft,
	SlideUp: TransitionSlideUp,
	SlideRight: TransitionSlideRight,
	SlideDown: TransitionSlideDown,
	Grow: GrowTransition,
	Fade: Fade
};

//-----------------------|| SNACKBAR ||-----------------------//

const Snackbar = () => {
	const [open, setOpen] = React.useState(false);

	const {closeSnackbar} = useSnackbar()
	const {t} = useTranslation()
	const snackbarInitial = useSelector((state: DefaultRootStateProps) => state.snackbar);

	const handleClose = (reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		closeSnackbar()
		// setOpen(false);
	};

	React.useEffect(() => {
		setOpen(snackbarInitial.open);
	}, [snackbarInitial.action, snackbarInitial.open]);

	return (
		<React.Fragment>
			{/* default snackbar */}
			{snackbarInitial.variant === 'default' && (
				<MuiSnackbar
					anchorOrigin={snackbarInitial.anchorOrigin}
					open={open}
					autoHideDuration={5000}
					onClose={(_: any, v: string) => handleClose(v)}
					message={snackbarInitial.message}
					TransitionComponent={transition[snackbarInitial.transition]}
					action={
						<React.Fragment>
							<Button color="secondary" size="small" onClick={() => handleClose()}>
								UNDO
							</Button>
							<IconButton size="small" aria-label="close" color="inherit" onClick={() => handleClose()}>
								<CloseIcon fontSize="small" />
							</IconButton>
						</React.Fragment>
					}
				/>
			)}

			{/* alert snackbar */}
			{snackbarInitial.variant === 'alert' && (
				<MuiSnackbar
					TransitionComponent={transition[snackbarInitial.transition]}
					anchorOrigin={snackbarInitial.anchorOrigin}
					open={open}
					autoHideDuration={5000}
					onClose={(_: any, v: string) => handleClose(v)}
				>
					<Alert
						variant="filled"
						severity={snackbarInitial.alertSeverity}
						sx={{
							bgcolor: snackbarInitial.alertSeverity + '.main',
							color: snackbarInitial.alertSeverity === 'warning' ? 'grey.800' : ''
						}}
						action={
							<React.Fragment>
								{snackbarInitial.actionButton !== false && (
									<Button color="secondary" size="small" onClick={() => handleClose()}>
										UNDO
									</Button>
								)}
								{snackbarInitial.close !== false && (
									<IconButton size="small" aria-label="close" color="inherit" onClick={() => handleClose()}>
										<CloseIcon fontSize="small" />
									</IconButton>
								)}
							</React.Fragment>
						}
					>
						{snackbarInitial.message && <FormattedMessage id={snackbarInitial.message} />}
					</Alert>
				</MuiSnackbar>
			)}

			{/* alert snackbar */}
			{snackbarInitial.variant === 'informative' && (
				<MuiSnackbar
					TransitionComponent={transition[snackbarInitial.transition]}
					anchorOrigin={snackbarInitial.anchorOrigin}
					open={open}
					
					autoHideDuration={5000}
					onClose={(_: any, v: string) => handleClose(v)}
				>
					<Alert
						variant="filled"
						severity="info"
						icon={snackbarInitial.icon}
						action={
							<React.Fragment>
								{snackbarInitial.actions && snackbarInitial.actions.map(({label, action}) => (
									<Button color="primary" size="small" onClick={action} >
										<Typography variant="button"> <FormattedMessage id={label} /></Typography>
									</Button>
								))}
								{snackbarInitial.href &&(
									<Button color="primary" size="small" href={snackbarInitial.href.url} >
										<Typography variant="button"> <FormattedMessage id={snackbarInitial.href.label} /></Typography>
									</Button>
								)}
								{snackbarInitial.close !== false && (
									<IconButton size="small" aria-label="close" color="inherit" onClick={() => handleClose()}>
										<CloseIcon fontSize="small" />
									</IconButton>
								)}
								
							</React.Fragment>
						}
					>
						{
							snackbarInitial.message &&
							t(snackbarInitial.message)
						}
					</Alert>
				</MuiSnackbar>
			)}
		</React.Fragment>
	);
};

export default Snackbar;
