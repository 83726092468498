import { IconButton, Menu, MenuItem, TableCell, TableRow, Theme, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import MoreVertIcon from '@mui/icons-material/MoreVert'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { User, UserStatus } from '../../../rebrain-irm-model'

const styles = ({palette, spacing}: Theme) => createStyles({
	table: {
	},
})

interface IState {
	anchorMenu?	:	any
}

interface IProps
	extends
	WithStyles<typeof styles>
	, WithTranslation
{
	user: User


	onClickUpdateUser?: (user: User) => void;
	onClickForgottenPassword?: (user: User) => void;
	onClickSendResetPasswordMail?: (user: User) => void;
	onClickDeleteUser?: (user: User) => void;

	onActivateUser?: (user: User) => void;
	onDisableUser?: (user: User) => void;
}

class UserRow extends React.Component<IProps, IState> {

	constructor(props: IProps) {
		super(props);
		this.state = {
		}
	}

	render = () => {
		const user = this.props.user

		return (
            <TableRow>

				<TableCell align="left"><Typography>{this.props.t(`user.status.${user.status}`, {defaultValue: user.status})}</Typography></TableCell>
				<TableCell><Typography>{user.email}</Typography></TableCell>
				{/* <TableCell align="right"><Typography>{user.firstname}</Typography></TableCell> */}
				<TableCell align="right"><Typography>{user.firstname} {user.lastname}</Typography></TableCell>
				<TableCell align="right"><Typography>{user.role}</Typography></TableCell>
				<TableCell align="right"><Typography>{user.hospitalCenter ? user.hospitalCenter.name : undefined}</Typography></TableCell>
				<TableCell align="right">
					<IconButton
                        onClick={(e) => this.setState({anchorMenu: e.currentTarget})}
                        size="large">
					<MoreVertIcon />
					</IconButton>
					<Menu
					id="simple-menu"
					anchorEl={this.state.anchorMenu}
					keepMounted
					open={this.state.anchorMenu !== undefined}
					onClose={this.__hideMenu}
					>
						{this.props.onClickUpdateUser && <MenuItem onClick={() => this.__hideMenu() && this.props.onClickUpdateUser && this.props.onClickUpdateUser(this.props.user)}>{this.props.t('update')}</MenuItem>}
						{this.props.onClickForgottenPassword && <MenuItem onClick={() => this.__hideMenu() && this.props.onClickForgottenPassword && this.props.onClickForgottenPassword(this.props.user)}>{this.props.t('change password')}</MenuItem>}
						{this.props.onClickSendResetPasswordMail && <MenuItem onClick={() => this.__hideMenu() && this.props.onClickSendResetPasswordMail && this.props.onClickSendResetPasswordMail(this.props.user)}>{this.props.t('send reset password mail')}</MenuItem>}
						{this.props.onActivateUser && user.status === UserStatus.DISABLE && <MenuItem onClick={() => this.__hideMenu() && this.props.onActivateUser && this.props.onActivateUser(this.props.user)}>{this.props.t('enable user')}</MenuItem>}
						{this.props.onDisableUser && user.status === UserStatus.ENABLE && <MenuItem onClick={() => this.__hideMenu() && this.props.onDisableUser && this.props.onDisableUser(this.props.user)}>{this.props.t('disable user')}</MenuItem>}
						{this.props.onClickDeleteUser && <MenuItem onClick={() => this.__hideMenu() && this.props.onClickDeleteUser && this.props.onClickDeleteUser(this.props.user)}>{this.props.t('delete')}</MenuItem>}
					</Menu>
				</TableCell>
			</TableRow>
        );
	}

	__hideMenu = () => {
		this.setState({anchorMenu: undefined})
		return true
	}
}



export default withTranslation() (withStyles(styles)(UserRow))