import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import {
	Button,
	createStyles,
	Grid, IconButton, Menu, MenuItem, Pagination, Paper, Skeleton, SortDirection, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Theme, Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import { ActionLogRequest } from 'apollo/ActionLogRequest';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormattedDate } from 'react-intl';
import ErrorDialog, { IErrorInformation } from '../../../components/errorDialog';
import { ActionLog, ActionLogFilters, PaginatedResponse } from '../../../rebrain-irm-model';
import FilterListIcon from '@mui/icons-material/FilterList';
import { LogsFiltersDialog } from './LogsFiltersDialog';
import { WithStyles, withStyles } from '@mui/styles';
import { HEADER } from 'config';

const styles = (theme: Theme) => createStyles({
	container: {
		[theme.breakpoints.up("sm")]: {
			height: `calc(100vh - ${HEADER.DASHBOARD_DESKTOP_HEIGHT}px)`,
			overflow: "hidden"
		}
	},
})

type LogsPageProps = WithStyles<typeof styles>;

const LogsPage = ({ classes }: LogsPageProps) => {
	// Hooks
	const { t } = useTranslation()

	// States
	const [logs, setLogs] = useState<PaginatedResponse<ActionLog>>({ items: [], totalCount: 0, hasMore: false })
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<IErrorInformation>()
	const [order, setOrder] = useState<SortDirection>("desc")
	const [orderBy, setOrderBy] = useState<keyof ActionLog>('creationDate')
	const [anchorEl, setAnchorEl] = React.useState<Element | ((element: Element) => Element) | null | undefined>(null);
	const [{ page, count }, setPaging] = useState({ page: 1, count: 30 })

	const [openFilters, setOpenFilters] = useState(false)
	const [filters, setFilters] = useState<ActionLogFilters>()

	// Refs
	const tableRef = useRef<HTMLTableElement>(null);

	// Constant
	const headCells: {
		id?: keyof ActionLog,
		label: string, disablePadding?: boolean,
		align?: 'inherit' | 'left' | 'center' | 'right' | 'justify',
		width?: string,
	}[] = [
		{
			// id: 'action',
			label: 'Action',
			align: 'center',
		},
		{
			id: 'description',
			label: 'Description',
			align: 'center',
			width: "60%",
			
		},
		{
			label: 'User',
			align: 'right',
			width: "20%",
		},
		{
			id: 'creationDate',
			label: 'Creation date',
			align: 'right',
		},
	];

	// Effects
	useEffect(() => {
		getLogs()
	}, [page, count, filters])

	useEffect(() => {
		if (page !== 1)
			setPaging({ page: 1, count })
		else
			getLogs()
	}, [order, orderBy])

	// Methods
	const getLogs = () => {
		setLoading(true)
		ActionLogRequest.logs(filters, {
			order: { order: order === 'asc' ? 'ASC' : 'DESC', key: orderBy },
			paging: { start: (page - 1) * count, count }
		}, "no-cache")
			.then((logs) => {
				setLogs(logs)
				tableRef.current && tableRef.current.scrollIntoView({});
			})
			.catch((err) => {
				setError({
					title: t('Error'),
					message: err.message,
				})
			})
			.finally(() => {
				setLoading(false)
			})
	}


	const onRequestSort = (key: keyof ActionLog) => (event: React.MouseEvent<HTMLElement>) => {
		const isActive = _.isEqual(key, orderBy)

		if (isActive) {
			setOrder(order === 'asc' ? 'desc' : 'asc')
		} else {
			setOrderBy(key)
		}
	}
	const __renderTable = () => {
		return (
			<TableContainer
				component={Paper} sx={{ maxHeight: "100%", width: "100%" }}>
				<Table ref={tableRef} stickyHeader size="small">
					<TableHead>
						<TableRow>
							{headCells.map((headCell, index, arr) => {
								const label = headCell.id ?
									<TableSortLabel
										active={orderBy === headCell.id}
										direction={orderBy === headCell.id && order ? order : 'asc'}
										onClick={onRequestSort(headCell.id)}
									>
										{t(headCell.label)}
										{orderBy === headCell.id ? (
											<Box component="span" sx={visuallyHidden}>
												{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
											</Box>
										) : null}
									</TableSortLabel>
									:
									t(headCell.label)

								return (
									<TableCell
										key={headCell.id}
										align={headCell.align}
										width={headCell.width}
										padding={headCell.disablePadding ? 'none' : 'normal'}
										sortDirection={orderBy === headCell.id ? order : false}
									>

										{
											index === arr.length - 1 ?
												<Stack width='inherit' direction="row" justifyContent="flex-end">

													{label}
													<IconButton

														sx={filters ? {
															backgroundColor: 'primary.main', color: 'white'
														} : undefined}
														onClick={() => setOpenFilters(true)}
													>
														<FilterListIcon />
													</IconButton>
												</Stack>
												:
												label
										}
									</TableCell>
								)
							}
							)}
						</TableRow>
					</TableHead>
					{
						__renderBody()
					}

				</Table>
			</TableContainer>
		)
	}

	const __renderBody = () => {
		if (loading) {
			return (
				<TableBody >
					{
						_.range(count).map((index) => (
							<TableRow key={index} >
								{
									_.range(headCells.length).map((index) => (
										<TableCell key={index} >
											<Skeleton width='100%' />
										</TableCell>
									))
								}
							</TableRow>
						))
					}

				</TableBody>
			)
		}
		return (
			<TableBody>
				{
					logs.items.map((log, index: number) => (
						<TableRow key={index}>
							<TableCell
								key="action"
								align="center"
							// padding={headCell.disablePadding ? 'none' : 'normal'}
							// sortDirection={orderBy === headCell.id ? order : false}
							>
								<Typography>
									{log.action}
								</Typography>
							</TableCell>
							<TableCell
								key="description"
								align="center"
							// padding={headCell.disablePadding ? 'none' : 'normal'}
							// sortDirection={orderBy === headCell.id ? order : false}
							>
								<Typography>
									{log.description}
								</Typography>
							</TableCell>
							<TableCell
								key="user"
								align="right"
							// padding={headCell.disablePadding ? 'none' : 'normal'}
							// sortDirection={orderBy === headCell.id ? order : false}
							>
								{
									log.user &&
									<Stack>

										<Typography variant="caption">
											{log.user.email}
										</Typography>
										<Typography variant="caption">
											{log.user._id}
										</Typography>
									</Stack>
								}
							</TableCell>
							<TableCell
								key="creationDate"
								align="right"
							// padding={headCell.disablePadding ? 'none' : 'normal'}
							// sortDirection={orderBy === headCell.id ? order : false}
							>
								<Stack direction="column" alignItems="flex-end">
									<Typography variant='caption'>
										{
											log.creationDate &&
											<FormattedDate value={log.creationDate} day='numeric' month='long' year='numeric' />
										}
									</Typography>
									<Typography variant='caption'>
										{
											log.creationDate &&
											<FormattedDate value={log.creationDate} hour='numeric' minute='2-digit' />
										}
									</Typography>
								</Stack>
							</TableCell>
						</TableRow>
					))
				}
			</TableBody>
		)
	}

	return (
		<Stack
			direction="column"
			sx={{ p: 2 }}
			spacing={2} className={classes.container}>
			{__renderTable()}
			<Grid container justifyContent="space-between" >
				<Grid item>
					<Pagination onChange={(_: any, value: number) => setPaging({ count, page: value })} page={page} count={Math.ceil(logs.totalCount / count)} color="primary" />
				</Grid>
				<Grid item>
					<Button
						size="large"
						sx={{ color: `grey.900` }}
						color="secondary"
						endIcon={<ExpandMoreRoundedIcon />}
						onClick={(e) => setAnchorEl(e.currentTarget)}
					>
						{count} {t('Rows')}
					</Button>
					<Menu
						id="menu-user-list-style1"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={() => setAnchorEl(undefined)}
						variant="selectedMenu"
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right'
						}}
					>
						<MenuItem onClick={() => setPaging({ page: 1, count: 10 })}> 10 {t('Rows')}</MenuItem>
						<MenuItem onClick={() => setPaging({ page: 1, count: 20 })}> 20 {t('Rows')}</MenuItem>
						<MenuItem onClick={() => setPaging({ page: 1, count: 30 })}> 30 {t('Rows')} </MenuItem>
					</Menu>
				</Grid>
			</Grid>
			<ErrorDialog error={error} onClose={() => setError(undefined)} />
			<LogsFiltersDialog
				onCancel={() => setOpenFilters(false)}
				onSaveFilters={(filters) => { setFilters(filters); setOpenFilters(false) }}
				fullWidth maxWidth="sm" open={openFilters} />
		</Stack>
	)
}


export default withStyles(styles)(LogsPage)