import { Button, Card, CardActions, CardContent, CardProps, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { APP_ACCEPT_COOKIES } from "store/actions";
import { DefaultRootStateProps } from "types";

const useStyles = makeStyles(({breakpoints}) => ({
	cookiesCard: {
		[breakpoints.up('lg')]: {
			maxWidth: "20vw"
		},
		position: 'fixed', bottom: 10, left: 10
	},
}))
interface CookiesManagementProps extends CardProps {
	
}
const CookiesManagement = ({sx, ...props}: CookiesManagementProps) => {
	const dispatch = useDispatch();
	
	return (
		<Card {...props} sx={{
			backgroundColor: `grey.100`,
			...sx
		}}>
			<CardContent>
				<Typography>
					This website only uses cookies that are strictly necessary for its operation, and cannot be blocked without breaking authentication features and session handling.
				</Typography>
			</CardContent>
			<CardActions>
				<Button variant="contained" color="secondary" onClick={() => dispatch({type: APP_ACCEPT_COOKIES})}>
					<Typography>
						I understand
					</Typography>
				</Button>
			</CardActions>
		</Card>
	)
}
export const AppLayout = ({children}: {children?: JSX.Element | JSX.Element[]}) => {
	// Hooks
	const appState = useSelector((state: DefaultRootStateProps) => state.app);
	const classes = useStyles()

	return (
		<div>
			{children}
			{
				!appState.isCookieManagementAccepted &&
				<CookiesManagement className={classes.cookiesCard}/>
			}
		</div>
	)
}