import { FetchPolicy } from "@apollo/react-hoc";
import { gql } from "apollo-boost";
import { Client } from ".";
import { HospitalCenter, HospitalCenterCreate, HospitalCenterUpdate } from "../rebrain-irm-model";

export const MCreateHospitalCenter = gql`
	mutation createHospitalCenter($createHospital: HospitalCenterCreateSchema!) {
		createHospitalCenter(hospitalCenter: $createHospital) {
			_id
		}
	}
`;

interface MCreateHospitalCenterArgs
{
	createHospital:	HospitalCenterCreate;
}

interface MCreateHospitalCenterData
{
	createHospitalCenter:	HospitalCenter;
}

export const QHospitalCenters = gql`
	query hospitalCenters {
		hospitalCenters {
			_id
			name
			address
		}
	}
`;

interface QHospitalCentersData
{
	hospitalCenters:	Array<HospitalCenter>;
}

export const QHospitalCenter = gql`
	query hospitalCenter($idHospitalCenter: Float!) {
		hospitalCenter(idHospitalCenter: $idHospitalCenter) {
			_id
			name
			address
		}
	}
`;

interface QHospitalCenterData
{
	hospitalCenter:	HospitalCenter;
}
interface QHospitalCenterArgs
{
	idHospitalCenter:	number;
}

export const MRemoveHospitalCenter = gql`
	mutation removeHospitalCenter($idHospitalCenter: Float!) {
		removeHospitalCenter(idHospitalCenter: $idHospitalCenter) {
			name
		}
	}
`;

interface MRemoveHospitalCenterArgs
{
	idHospitalCenter:	number;
}

interface MRemoveHospitalCenterData
{
	removeHospitalCenter:	HospitalCenter;
}

export const MUpdateHospitalCenter = gql`
	mutation updateHospitalCenter($updateHospital: HospitalCenterUpdateSchema!) {
		updateHospitalCenter(hospitalCenter: $updateHospital) {
			_id
			name
			address
		}
	}
`;

interface MUpdateHospitalCenterArgs
{
	updateHospital:	HospitalCenterUpdate;
}

interface MUpdateHospitalCenterData
{
	updateHospitalCenter:	HospitalCenter;
}

export class HospitalCenterRequests {

	
	public static async createHospitalCenter(createHospital: HospitalCenterCreate): Promise<HospitalCenter> {
		return (await Client.mutate<MCreateHospitalCenterData, MCreateHospitalCenterArgs>({
			mutation: MCreateHospitalCenter,
			variables: {
				createHospital
			},
		})).data!.createHospitalCenter
	}

	public static async hospitalCenters(fetchPolicy?: FetchPolicy): Promise<Array<HospitalCenter>> {
		return (await Client.query<QHospitalCentersData>({
			query: QHospitalCenters,
			fetchPolicy
		})).data.hospitalCenters
	}

	public static async hospitalCenter(idHospitalCenter: number, fetchPolicy?: FetchPolicy): Promise<HospitalCenter> {
		return (await Client.query<QHospitalCenterData, QHospitalCenterArgs>({
			query: QHospitalCenter,
			fetchPolicy,
			variables: {
				idHospitalCenter
			}
		})).data.hospitalCenter
	}

	public static async removeHospitalCenter(idHospitalCenter: number): Promise<HospitalCenter> {
		return (await Client.mutate<MRemoveHospitalCenterData, MRemoveHospitalCenterArgs>({
			mutation: MRemoveHospitalCenter,
			variables: {
				idHospitalCenter
			},
		})).data!.removeHospitalCenter
	}

	public static async updateHospitalCenter(updateHospital: HospitalCenterUpdate): Promise<HospitalCenter> {
		return (await Client.mutate<MUpdateHospitalCenterData, MUpdateHospitalCenterArgs>({
			mutation: MUpdateHospitalCenter,
			variables: {
				updateHospital
			},
		})).data!.updateHospitalCenter
	}
}