import i18n from "../i18n";
import { PatientRecordStatus } from "../rebrain-irm-model";

export default class FormatToolbox {
	static patientRecordEnumToString(status: PatientRecordStatus): string | undefined {
		switch (status) {
			case PatientRecordStatus.TO_DO:
				return i18n.t('to do')
			case PatientRecordStatus.IN_PROGRESS:
				return i18n.t('in progress')
			case PatientRecordStatus.PREOP_MRI_MARKED:
				return i18n.t('Targeting completed')
			case PatientRecordStatus.POSTOP_TO_PROCESS:
				return i18n.t('To do Postoperative checking')
			case PatientRecordStatus.DOWNLOADED:
				return i18n.t('downloaded')
			case PatientRecordStatus.GIVE_UP:
				return i18n.t('rejected')
			case PatientRecordStatus.DONE:
				return i18n.t('Checking completed')
			default:
				return i18n.t('unknown')
		}
	}
}