import { withApollo, WithApolloClient } from '@apollo/react-hoc'
import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    Select,
    TextField,
    Theme,
    OutlinedInput,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { GraphQLError } from 'graphql'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { HospitalCenterRequests, UserRequests } from '../../../apollo'
import CreateSomethingWrapper from '../../../components/createSomethingWrapper'
import ErrorDialog, { IErrorInformation } from '../../../components/errorDialog'
import InformativeDialog, { IInformative } from '../../../components/informativeDialog'
import ConfirmationDialog, { ConfirmationInformation } from '../../../components/confirmationDialog'
import { HospitalCenter, Roles, User, UserCreate } from '../../../rebrain-irm-model'
import { UsersRouter } from '../../../router/dashboard/users/users.router'
const styles = ({palette, spacing}: Theme) => createStyles({
	title: {
		fontWeight: 'bolder'
	},
	gradient: {

	}
})


interface IState {
	user: UserCreate;
	fieldErrors:	{[key: string]: string};

	error?: IErrorInformation;
	information?: IInformative;
	confirmation?: ConfirmationInformation;

	hospitals: Array<HospitalCenter>
}

interface IProps
	extends
	WithStyles<typeof styles>
	, RouteComponentProps
	, WithTranslation
	, WithApolloClient<{}>
{

}

class CreateUserPage extends React.Component<IProps, IState> {

	constructor(props: IProps) {
		super(props);
		this.state = {
			// errorMsg: undefined,
			// progress: false,
			fieldErrors: {},
			user: {
				email: "",
				password: "",
				role: "" as Roles
			},
			hospitals: [
			]
		}
	}

	componentDidMount = () => {
		this.__loadHospitals()
	}

	render = () => {

		return(
			
			<CreateSomethingWrapper
				title={this.props.t('create an user')}
				onPressCancel={this.__onPressCancel}
				onPressCreate={this.__onPressCreate}
				>
				{this.__renderForm()}
				<ErrorDialog error={this.state.error} onClose={() => this.setState({error: undefined})}/>
				<InformativeDialog information={this.state.information} onClose={() => {}}/>
				<ConfirmationDialog confirmation={this.state.confirmation} onClose={() => {}}/>

			</CreateSomethingWrapper>
		)
	}

	__renderForm = () => {
		return (
            <form>
			<Grid
				item
				container
				spacing={2}
				justifyContent="space-between"
				alignItems="center">

					<Grid item xs={12} sm={6}>	
						<TextField
							error={this.state.fieldErrors.firstname !== undefined}
							helperText={this.state.fieldErrors.firstname}
							id="firstname"
							label={this.props.t('firstname')}
							InputLabelProps={{
								shrink: true,
							}}
							fullWidth
							variant="outlined"
							value={this.state.user.firstname}
							onChange={(e) => this.__onChange("firstname", e.target.value)}
							/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField
							error={this.state.fieldErrors.lastname !== undefined}
							helperText={this.state.fieldErrors.lastname}
							id="lastname"
							label={this.props.t('lastname')}
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							value={this.state.user.lastname}
							onChange={(e) => this.__onChange("lastname", e.target.value)}
							/>
					</Grid>

				<Grid item xs={6}>
  					<TextField
						error={this.state.fieldErrors.email !== undefined}
						helperText={this.state.fieldErrors.email}
						id="email"
						label={this.props.t('email')}
						fullWidth
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						value={this.state.user.email}
						onChange={(e) => this.__onChange("email", e.target.value)}
						/>
				</Grid>
				<Grid item xs={1} sm={6}/>
				<Grid item xs={12} sm={6}>
  					<TextField
						error={this.state.fieldErrors.password !== undefined}
						helperText={this.state.fieldErrors.password}
						id="password"
						fullWidth
						InputLabelProps={{
							shrink: true,
						}}
						label={this.props.t('password')}
						variant="outlined"
						value={this.state.user.password}
						onChange={(e) => this.__onChange("password", e.target.value)}
						/>
				</Grid>
				<Grid item xs={1} sm={6}/>
				<Grid item xs={12} sm={6}>
					<FormControl variant="outlined" fullWidth error={this.state.fieldErrors.idHospitalCenter !== undefined}>
						<InputLabel  shrink={true} htmlFor="hospital-native-simple">{this.props.t('hospital')}</InputLabel>
						<Select
						native
						variant="outlined"
						value={this.state.user.idHospitalCenter}
						onChange={(e) => this.__onChange("idHospitalCenter", Number(e.target.value))}

						inputProps={{ id: 'hospital-native-simple'}}
						input={<OutlinedInput notched label={this.props.t('hospital')} />}
						>
							<option value={undefined}></option>
							{
								this.state.hospitals.map((hospital) => <option value={hospital._id}>{hospital.name}</option>)
							}
						</Select>
						<FormHelperText>{this.state.fieldErrors.idHospitalCenter}</FormHelperText>
					</FormControl>
				</Grid>
				<Grid item xs={1} sm={6}/>
				<Grid item xs={12} sm={6}>
					<FormControl variant="outlined" error={this.state.fieldErrors.role !== undefined}>
						<InputLabel shrink={true} htmlFor="role-native-simple">{this.props.t('role')}</InputLabel>
						<Select
						native
						variant="outlined"
						value={this.state.user.role}
						onChange={(e) => this.__onChange("role", e.target.value)}

						inputProps={{ id: 'role-native-simple'}}
						input={<OutlinedInput notched label={this.props.t('role')} />}
						>
							<option value={undefined}></option>
							<option value={Roles.ADMIN}>{Roles.ADMIN}</option>
							<option value={Roles.CLINICIAN}>{Roles.CLINICIAN}</option>
							<option value={Roles.OPERATOR}>{Roles.OPERATOR}</option>
						</Select>
						<FormHelperText>{this.state.fieldErrors.role}</FormHelperText>
					</FormControl>
				</Grid>
				<Grid item xs={1} sm={6}/>

			</Grid>
			</form>
        );
	}

	__onChange = (field: keyof UserCreate, value: any) => {
		const user = this.state.user;

		(user[field] as any) = value
		this.setState({user})
	}

	__onPressCancel = () => {
		this.__goBackToUserList()
	}
	__onPressCreate = () => {
		const user = this.state.user
		const errors: {[key: string]: string} = {}
		/** CHECK SOME FIELDS */
		if (user.email.length <= 0)
			errors.email = this.props.t('thanks to set a valid mail')
		var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
		if (!pattern.test(user.email))
			errors.email = this.props.t('thanks to set a valid mail')
		if (user.password.length <= 0)
			errors.password = this.props.t('thanks to set a valid password')
		if (user.role.length <= 0)
			errors.role = this.props.t('thanks to set a valid role')
		if (!user.firstname || user.firstname.length <= 0)
			errors.firstname = this.props.t('thanks to set a valid firstname')
		if (!user.lastname || user.lastname.length <= 0)
			errors.lastname = this.props.t('thanks to set a valid lastname')
		// if (user.idHospitalCenter === undefined || user.idHospitalCenter === null)
			// errors.idHospitalCenter = this.props.t('thanks to set a valid hospital center')
		if (!([Roles.ADMIN, Roles.OPERATOR].includes(user.role)) && !user.idHospitalCenter)
			errors.idHospitalCenter = this.props.t('thanks to set a valid hospital center')



		if (Object.keys(errors).length > 0)
			return this.setState({fieldErrors: errors})

		this.setState({fieldErrors: {}, information: {
			title: this.props.t('creating'),
			showProgress: true,
			message: `${this.props.t('updating')}...`
		}})

		UserRequests.createUser(user)
		.then((user: User) => {
			this.__showPopupAskingSendMailAndClose(user)
		}).catch((error: GraphQLError) => {
			this.setState({information: undefined, error: {
				title: this.props.t('error'),
				message: error.message,
				
			}})
		})
	}

	/**
	 * Method used to show a confirmation popup to send mail to "reset password". And close the page
	 */
	__showPopupAskingSendMailAndClose = (user: User) => {
		this.setState({information: undefined, confirmation: {
			title: `${this.props.t('user created')} !`,
			message: `${this.props.t('user have been created')}. ${this.props.t('would you like send the mail to reset password ?')}`,
			onClickAccept: () => {
				this.setState({information: {
					title: this.props.t('loading'),
					showProgress: true,
				}})
				
				UserRequests.forgottenPassword(user.email).then(() => {
					this.setState({information: {
						showProgress: false,
						message: this.props.t('mail to reset password has been sent'),
						onClose: this.__goBackToUserList
					}})
				}).catch((err: GraphQLError) => {
					this.setState({information: undefined, error: {
						title: this.props.t('error'),
						message: err.message,
						onClose: this.__goBackToUserList
					}})
				})
			},
			onClickCancel: this.__goBackToUserList
		}})
	}

	__loadHospitals = () => {
		HospitalCenterRequests.hospitalCenters()
		.then((hospitals) => this.setState({hospitals}))
		.catch((error: GraphQLError) => {
			this.setState({information: undefined, error: {
				title: this.props.t('error'),
				message: error.message,
				
			}})
		})
	}

	__goBackToUserList = () => {
		this.props.history.push(UsersRouter.getRoute())
	}
}



export default withTranslation()( withApollo(withRouter(withStyles(styles)(CreateUserPage)) as any))