import { withApollo, WithApolloClient } from '@apollo/react-hoc';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import {
	Button,
	ButtonGroup,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	OutlinedInput,
	Select, Stack, TextField,
	Theme,
	Typography
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import ConfirmationDialog, { ConfirmationInformation } from 'components/confirmationDialog';
import JSZip from 'jszip';
import path from 'path';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { PatientRecordRequests } from '../../../apollo';
import CreateSomethingWrapper from '../../../components/createSomethingWrapper';
import ErrorDialog, { IErrorInformation } from '../../../components/errorDialog';
import FileListDialog, { FileListDialogProps } from '../../../components/FileListDialog';
import InformativeDialog, { IInformative } from '../../../components/informativeDialog';
import { PatientRecordCreate, PatientRecordPathology, PatientRecordSide, PatientRecordTarget, PatientRecordTypeOfSurgery } from '../../../rebrain-irm-model';
import { PatientRecordsRouter } from '../../../router/dashboard/patientRecords/patientRecords.router';

const styles = (theme: Theme) => createStyles({
	circle: {
		display: "inline-block",
		backgroundColor: theme.palette.primary.main,
		borderRadius: "50%",
		width: 5,
		height: 5,
	},
})

interface IState {
	patientRecord: PatientRecordCreate;
	newNoTargetedIrm?	:	Array<File> | undefined

	fieldErrors?:	{[key: string]: string};
	error?: IErrorInformation;
	information?: IInformative;
	fileList?: FileListDialogProps;

	confirmation?: ConfirmationInformation;

}

interface IProps
	extends
	WithStyles<typeof styles>
	, RouteComponentProps
	, WithTranslation
	, WithApolloClient<{}>
{

}

class CreatePatientRecordPage extends React.Component<IProps, IState> {

	_folderInput: HTMLInputElement | null = null;
	_fileInput: HTMLInputElement | null = null;

	constructor(props: IProps) {
		super(props);
		this.state = {
			patientRecord: {
			},
		}
	}

	componentDidMount = () => {
	}


	setFolderInput = (ref: HTMLInputElement | null) => {
		this._folderInput = ref
		if (this._folderInput) {
			// Allow folder uploads
			this._folderInput.webkitdirectory = true
			this._folderInput.multiple = true
			this._folderInput.type = "file"
		}
	}
	setFileInput = (ref: HTMLInputElement | null) => {
		this._fileInput = ref
		if (this._fileInput) {
			this._fileInput.multiple = true
			this._fileInput.type = "file"
		}
	}

	render = () => {
		return(
			<div>
				{
					process.env.NODE_ENV === "development" &&
					<Alert severity="info">
						<Stack spacing={1} direction="row" alignItems="center">

							<Typography>{this.props.t('You are in a development environment')}. 
							{this.props.t('Would you like to complete the form ?')}</Typography>
							<Button variant="contained" color="secondary" onClick={this.__onClickFillForm}>
								{this.props.t('Fill')}
							</Button>
						</Stack>
					</Alert>
				}
				<CreateSomethingWrapper
					title={this.props.t('create patient record')}
					onPressCancel={this.__onPressCancel}
					onPressCreate={this.__onPressCreate}>
					{this.__renderForm()}
					<ErrorDialog error={this.state.error} onClose={() => this.setState({error: undefined})}/>
					<InformativeDialog information={this.state.information} onClose={() => {}}/>
					<FileListDialog fileListProps={this.state.fileList} onClose={() => this.setState({fileList: undefined})}/>
				</CreateSomethingWrapper>
				<ConfirmationDialog
					confirmation={this.state.confirmation}
					onClose={() => this.setState({confirmation: undefined})}/>
			</div>
		)
	}

	__renderForm = () => {
		return (
			<form>
			<Grid
				item
				container
				spacing={2}
				justifyContent="space-between"
				alignItems="center">

					<Grid item xs={12} sm={6}>	
						<TextField
							error={this.state.fieldErrors?.firstname !== undefined}
							helperText={this.state.fieldErrors?.firstname}
							id="firstname"
							label={this.props.t('firstname')}
							InputLabelProps={{
								shrink: true,
							}}
							fullWidth
							variant="outlined"
							value={this.state.patientRecord.firstname}
							onChange={(e) => this.__onChange("firstname", e.target.value)}
							/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField
							error={this.state.fieldErrors?.lastname !== undefined}
							helperText={this.state.fieldErrors?.lastname}
							id="lastname"
							label={this.props.t('lastname')}
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							value={this.state.patientRecord.lastname}
							onChange={(e) => this.__onChange("lastname", e.target.value)}
							/>
					</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl variant="outlined" fullWidth error={this.state.fieldErrors?.pathologie !== undefined}>
						<InputLabel shrink={true} htmlFor="pathologie-native-simple">{this.props.t('pathology')}</InputLabel>
						<Select
						native
						variant="outlined"
						value={this.state.patientRecord.pathologie}
						onChange={(e) => this.__onChange("pathologie", e.target.value)}

						inputProps={{ id: 'pathologie-native-simple'}}
						input={<OutlinedInput notched label={this.props.t('pathology')} />}
						>
							<option value={undefined}></option>
							<option value={PatientRecordPathology.PARKINSON}>{this.props.t(PatientRecordPathology.PARKINSON)}</option>
							<option value={PatientRecordPathology.ESSENTIAL_TREMOR}>{this.props.t(PatientRecordPathology.ESSENTIAL_TREMOR)}</option>
						</Select>
						<FormHelperText>{this.state.fieldErrors?.pathologie}</FormHelperText>
					</FormControl>
				</Grid>

				

				<Grid item xs={12} sm={6}>
					<FormControl variant="outlined" fullWidth error={this.state.fieldErrors?.target !== undefined}>
						<InputLabel shrink={true} htmlFor="target-native-simple">{this.props.t('target')}</InputLabel>
						<Select
						native
						variant="outlined"
						value={this.state.patientRecord.target}
						onChange={(e) => this.__onChange("target", e.target.value)}

						inputProps={{ id: 'target-native-simple'}}
						input={<OutlinedInput notched label={this.props.t('target')} />}
						>
							<option value={undefined}></option>
							{
								this.state.patientRecord.pathologie === PatientRecordPathology.PARKINSON &&
								<option value={PatientRecordTarget.STN}>{this.props.t(PatientRecordTarget.STN)}</option>
							}
							<option value={PatientRecordTarget.VIM}>{this.props.t(PatientRecordTarget.VIM)}</option>
						</Select>
						<FormHelperText>{this.state.fieldErrors?.target}</FormHelperText>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl variant="outlined" fullWidth error={this.state.fieldErrors?.side !== undefined}>
						<InputLabel shrink={true} htmlFor="side-native-simple">{this.props.t('side')}</InputLabel>
						<Select
						native
						variant="outlined"
						value={this.state.patientRecord.side}
						onChange={(e) => this.__onChange("side", e.target.value)}

						inputProps={{ id: 'side-native-simple'}}
						input={<OutlinedInput notched label={this.props.t('side')} />}
						>
							<option value={undefined}></option>
							<option value={PatientRecordSide.BILATERAL}>{this.props.t(PatientRecordSide.BILATERAL)}</option>
							<option value={PatientRecordSide.LEFT}>{this.props.t(PatientRecordSide.LEFT)}</option>
							<option value={PatientRecordSide.RIGHT}>{this.props.t(PatientRecordSide.RIGHT)}</option>
						</Select>
						<FormHelperText>{this.state.fieldErrors?.side}</FormHelperText>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl variant="outlined" fullWidth error={this.state.fieldErrors?.typeOfSurgery !== undefined}>
						<InputLabel shrink={true} htmlFor="typeOfSurgery-native-simple">{this.props.t('Type of Surgery')}</InputLabel>
						<Select
						native
						variant="outlined"
						value={this.state.patientRecord.typeOfSurgery}
						onChange={(e) => this.__onChange("typeOfSurgery", e.target.value)}

						inputProps={{ id: 'typeOfSurgery-native-simple'}}
						input={<OutlinedInput notched label={this.props.t('Type of Surgery')} />}
						>
							<option value={undefined}></option>
							<option value={PatientRecordTypeOfSurgery.DBS}>{this.props.t(PatientRecordTypeOfSurgery.DBS)}</option>
							<option value={PatientRecordTypeOfSurgery.HIFU}>{this.props.t(PatientRecordTypeOfSurgery.HIFU)}</option>
							<option value={PatientRecordTypeOfSurgery.RADIOSURGERY}>{this.props.t(PatientRecordTypeOfSurgery.RADIOSURGERY)}</option>
						</Select>
						<FormHelperText>{this.state.fieldErrors?.typeOfSurgery}</FormHelperText>
					</FormControl>
				</Grid>

				<Grid item xs={12} container spacing={2}>
					<Grid item sm={3}><Typography variant="h6">{this.props.t('no targeted IRM')}:</Typography></Grid>
					<Grid item>
						<ButtonGroup variant="contained" color="secondary">
							<Button
								startIcon={<AddCircleIcon />}
								// component="span"
								onClick={()=> this._fileInput && this._fileInput.click()}
								>
								<input
									ref={this.setFileInput}
									onChange={e => this.__onUploadNoTargetedIrm(Array.prototype.slice.call(e.target.files))}
									multiple
									type="file"
									hidden
								/>
								<Typography variant="caption">{this.props.t('new file')}</Typography>
							</Button>
							<Button
								onClick={()=> this._folderInput && this._folderInput.click()}
								>
								<input
									ref={this.setFolderInput}
									onChange={e => this.__onUploadNoTargetedIrm(Array.prototype.slice.call(e.target.files))}
									multiple
									type="file"
									hidden
								/>
								<Typography variant="caption">{this.props.t('folder')}</Typography>
							</Button>
						</ButtonGroup>
					</Grid>
					<Grid item>
						<Button
							startIcon={<DescriptionIcon />}
							// href={`${process.env.REACT_APP_SERVER}/patient-record/targeted-irm/${record._id}`}
							variant="contained"
							color="secondary"
							onClick={this.__onClickShowIrm}
							disabled={!this.state.newNoTargetedIrm}>
							<Typography variant="caption">{this.props.t('display files')}</Typography>
						</Button>
					</Grid>
					{
						this.state.newNoTargetedIrm &&
						this.state.newNoTargetedIrm.map(a => a.size).reduce((a, b) => a + b, 0) > Number(process.env.REACT_APP_MAX_UPLOAD_SIZE) ?
						<Grid item xs={12}>
							<Alert severity="error">
								{this.props.t('The data you want to submit exceeds the file size limit of 300MB')}.
							</Alert>
						</Grid>
						:
						<Grid item xs={12}>
							<Alert severity="info">
								{this.props.t('The total maximum files size is 300MB')}.
							</Alert>
						</Grid>
					}
				</Grid>

			</Grid>
			</form>
		);
	}

	__onUploadNoTargetedIrm = (acceptedFiles: File[]) => {

		this.setState({
			confirmation: {
				// title: this.props.t('Files to save')
				message: `${this.props.t('Please be sure that the MRI is pseudonymized before you upload it')}.`,
				onClickAccept: () => {
					this.setState({confirmation: undefined});
					if (acceptedFiles.length >= 1) {
						this.setState({ newNoTargetedIrm: acceptedFiles })
					}
				},
				onClickCancel: () => this.setState({confirmation: undefined})
			}
		})
	}

	__onChange = (field: keyof PatientRecordCreate, value: any) => {
		const patientRecord = this.state.patientRecord;

		(patientRecord[field] as any) = value
		
		// Special case
		if (
			patientRecord.pathologie === PatientRecordPathology.ESSENTIAL_TREMOR
			&& patientRecord.target !== PatientRecordTarget.VIM
		)
			patientRecord.target = "" as PatientRecordTarget

		this.setState({patientRecord})
	}

	__onClickFillForm = () => {
		this.setState({
			patientRecord: {
				firstname: "John",
				lastname: "Doe",
				pathologie: PatientRecordPathology.PARKINSON,
				target: PatientRecordTarget.STN,
				side: PatientRecordSide.LEFT,
				typeOfSurgery: PatientRecordTypeOfSurgery.DBS
			}
		})
	}

	__onClickShowIrm = () => {
		this.setState({fileList: {
			title: this.props.t('files to save'),
			files: this.state.newNoTargetedIrm?.map((f) => f.name)
		}})
	}
	__onPressCancel = () => {
		// this.props.history.goBack()
		this.props.history.push(PatientRecordsRouter.getRoute())
	}
	__onPressCreate = () => {
		const patientRecord = this.state.patientRecord
		const zip = new JSZip()
		const files = this.state.newNoTargetedIrm
		const errors: {[key: string]: string} = {}		

		//Check fields
		if (!patientRecord.firstname || patientRecord.firstname.length <= 0)
			errors.firstname = this.props.t('thanks to set a valid firstname')
		if (!patientRecord.lastname || patientRecord.lastname.length <= 0)
			errors.lastname = this.props.t('thanks to set a valid lastname')
		
		if (!patientRecord.target || patientRecord.target.trim().length === 0)
			errors.target = this.props.t('thanks to set a valid target')
		if (!patientRecord.side || patientRecord.side.trim().length === 0)
			errors.side = this.props.t('thanks to set a valid side')
		if (!patientRecord.typeOfSurgery || patientRecord.typeOfSurgery.trim().length === 0)
			errors.typeOfSurgery = this.props.t('thanks to set a valid type of surgery')
		if (!patientRecord.pathologie || patientRecord.pathologie.trim().length === 0)
			errors.pathologie = this.props.t('thanks to set a valid pathology')

		if (Object.keys(errors).length > 0)
			return this.setState({fieldErrors: errors})

		if (!files || files.length === 0)
			return this.setState({fieldErrors: undefined, error: {
				title: this.props.t('error'),
				message: `${this.props.t('thanks to upload an IRM')} !`
			}})

		if (files.map(a => a.size).reduce((a, b) => a + b, 0) > Number(process.env.REACT_APP_MAX_UPLOAD_SIZE))
			return this.setState({fieldErrors: undefined, error: {
				title: this.props.t('error'),
				message: `${this.props.t('The data you want to submit exceeds the file size limit of 300MB')} !`
			}})


		// Check file list contains one zip only
		this.setState({fieldErrors: undefined, information: {
			title: this.props.t('creating'),
			showProgress: true,
			message: `${this.props.t('creating a new patient record')}...`
		}})
		

		const push = async () => {
			const isZip = files.length === 1 && files.map(f => path.extname(f.name) === ".zip").reduce((a, b) => a && b, true)
			let file;

			if (isZip) {
				file = files[0]
			} else {
				files.forEach((file) => {
					zip.file(file.name, file)
				})
				file = await zip.generateAsync({type:"blob"})
			}

			await PatientRecordRequests.createPatientRecord(this.state.patientRecord, file)
		}

		push()
		.then(() => {
			this.setState({information: {
				title: `${this.props.t('patient record saved')} !`,
				message: `${this.props.t('patient record have been saved')}.`,
				onClose: () => this.props.history.push(PatientRecordsRouter.getRoute())
			}})
			setTimeout(() => this.props.history.push(PatientRecordsRouter.getRoute()), 1500)
		})
		.catch(err => {
			this.setState({error: {
				title: this.props.t('error'),
				message: err.message
			}})
		})
		.finally(() => {
			this.setState({information: undefined})
		})
	}
}



export default withTranslation() (withApollo(withRouter(withStyles(styles)(CreatePatientRecordPage)) as any))