import { Box, Button, Grid, InputAdornment, Paper, TextField, Theme, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
// import banniere from '../../assets/LOGO BLANC-300.png';
import banniere from '../../assets/LOGO BLANC-300.png';

const styles = ({palette, spacing}: Theme) => createStyles({
	paper: {
		backgroundColor: '#3e3c3cBB',
		color: '#ffffff',
	},
	input: {
		flex: 1,
		width: '100%'
	},
	iconButton: {
		marginRight: 10,
		marginLeft: 10,
		color: '#3e3c3cBB',
	},
	margin: {
		margin: 10
	},
	forgottenPassword: {
		textDecorationLine: 'underline',
		color: '#ffffff'
	},
	logo: {
		maxWidth: 200
	}
})

interface IState {
	mail: string;

	mailVerification: string;

	mailVerificationError: string | null;
	mailError: string | null;
}

interface IProps extends
	WithStyles<typeof styles>
	, WithTranslation
	{
	onClickResetPassword?: (mail: string) => void;
	onClickSignin?:	(mail: string) => void;

	errorMsg?:	string;
}

class ForgottenPasswordForm extends React.Component<IProps, IState> {

	constructor(props: IProps) {
		super(props)

		this.state = {
			mail: "",
			mailVerification: "",
			mailVerificationError: null,
			mailError: null
		}
	}
	render = () => {
		const classes = this.props.classes;
		return (
            <Paper className={classes.paper} variant="outlined" elevation={3} component="form" onSubmitCapture={(e:any) => {e.preventDefault(); this.__onClickResetPassword()}}>
				<Box pb={5} pt={2}>
			
					<Grid
						container
						direction="column"
						justifyContent="center"
						alignItems="stretch"
						>
							<Box mx="auto" pb={5}>
								<img src={banniere} alt="logo" className={classes.logo}/>
								{/* <Typography variant="h1">foodr</Typography> */}
							</Box>


							<Box px={5} >
								<Paper >
									<TextField
										variant="outlined"
										onChange={(e) => this.setState({mail: e.target.value, mailVerificationError: null, mailError: null})}
										type="mail"
										color="primary"
										className={classes.input}
										error={this.state.mailError !== null}
										helperText={this.state.mailError}
										placeholder={this.props.t("mail address")}
										InputProps={{
											startAdornment:
											<InputAdornment position="start">
											<AccountCircleIcon className={classes.iconButton} />
											</InputAdornment>
										}}
									/>
								</Paper>
							</Box>

							<Box px={5} py={1}>
								<Paper >
									<TextField
										variant="outlined"
										error={this.state.mailVerificationError !== null}
										helperText={this.state.mailVerificationError}
										onChange={(e) => this.setState({mailVerification: e.target.value, mailVerificationError: null, mailError: null})}
										className={classes.input}
										placeholder={this.props.t("mail address")}
										InputProps={{
											startAdornment: 
												<InputAdornment position="start">
												<AccountCircleIcon className={classes.iconButton} />
												</InputAdornment>
										}}
									/>
								</Paper>
							</Box>
							<Box p={2} mx="auto">
								<Button
									onClick={this.__onClickResetPassword}
									color="secondary"
									variant="contained"
									size="large">
									{this.props.t("reset your password")}
								</Button>
							</Box>
							<Grid item>
								<Box px={5}>
									<Button onClick={() => this.props.onClickSignin && this.props.onClickSignin(this.state.mail)}>
										<Typography
											variant="caption"
											className={classes.forgottenPassword}>
												{this.props.t("connection")}
										</Typography>
									</Button>

								</Box>
							</Grid>
							{(
								this.props.errorMsg ?
									<Box mx="auto">
										<Typography color="error">{this.props.errorMsg}</Typography>
									</Box>
								: null
							)}
					</Grid>

				</Box>
			</Paper>
        );
	}

	__onClickResetPassword = () => {
		if (this.state.mail.length === 0)
			return this.setState({mailError: this.props.t('thanks to set a valid mail')})
		else if (this.state.mailVerification !== this.state.mail)
			return this.setState({mailVerificationError: this.props.t('mail addresses differ')})
		
		this.setState({mailVerificationError: null, mailError: null})
		if (this.props.onClickResetPassword)
			this.props.onClickResetPassword(this.state.mail)
	}
 
}



export default withTranslation() (withStyles(styles)(ForgottenPasswordForm))