// import { LocalizationProvider as LP } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Locale } from 'date-fns';
import enLocale from "date-fns/locale/en-US";
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
// third-party
import { IntlProvider, MessageFormatElement } from 'react-intl';


//-----------------------|| LOCALIZATION ||-----------------------//
export interface LocalsProps {
	children: React.ReactNode;
}

// load locales files
// const loadLocaleData = (locale?: string) => {
// 	switch (locale) {
// 		default:
// 			return import('../assets/locales/en/translation.json');
// 	}
// };

const LocalizationProvider = ({ children }: LocalsProps) => {
	const [locale] = useState<Locale>(enLocale);
	const [messages, setMessages] = useState<Record<string, string> | Record<string, MessageFormatElement[]> | undefined>();

	// useEffect(() => {
	// 	loadLocaleData().then(
	// 		(d: { default: Record<string, string> | Record<string, MessageFormatElement[]> | undefined }) => {
	// 			setMessages(d.default);
	// 		}
	// 	);

	// }, [])
	return (
		<React.Fragment>
			<I18nextProvider i18n={i18n}>
				{/* <LP locale={locale} dateAdapter={AdapterDateFns}> */}
					<IntlProvider locale="en" defaultLocale="en" messages={messages}>
						{children}
					</IntlProvider>
				{/* </LP> */}
			</I18nextProvider>
		</React.Fragment>
	);
};

export default LocalizationProvider;
