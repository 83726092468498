import { withApollo, WithApolloClient } from '@apollo/react-hoc'
import { Grid, TextField, Theme, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { GraphQLError } from 'graphql'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ServerRequests } from '../../../apollo'
import CreateSomethingWrapper from '../../../components/createSomethingWrapper'
import ErrorDialog, { IErrorInformation } from '../../../components/errorDialog'
import InformativeDialog, { IInformative } from '../../../components/informativeDialog'
import { MailSettings } from '../../../rebrain-irm-model'
import { UsersRouter } from '../../../router/dashboard/users/users.router'
const styles = ({palette, spacing}: Theme) => createStyles({
})

interface IState {
	error?: IErrorInformation;
	information?: IInformative;

	mailSettings: MailSettings
}

interface IProps
	extends
	WithStyles<typeof styles>
	, RouteComponentProps
	, WithTranslation
	, WithApolloClient<{}>
{

}

class SettingsPage extends React.Component<IProps, IState> {

	constructor(props: IProps) {
		super(props);
		this.state = {
			mailSettings: {
				mail: null,
				password: null,
				service: null,
				smtp: null,
				port: null
			}
		}
	}

	componentDidMount = () => {
		this.__loadSettings()
	}

	render = () => {

		return(
			
			<CreateSomethingWrapper
				title={this.props.t('settings')}
				onPressCancel={this.__onPressCancel}
				onPressCreate={this.__onPressCreate}
				>
				{this.__renderForm()}
				<ErrorDialog error={this.state.error} onClose={() => this.setState({error: undefined})}/>
				<InformativeDialog information={this.state.information} onClose={() => {}}/>

			</CreateSomethingWrapper>
		)
	}

	__renderForm = () => {
		return (
            <Grid
				component="form"
				item
				container
				spacing={2}
				justifyContent="space-between"
				alignItems="center">
				<Grid item xs={12}>
					<Typography variant="h6">{this.props.t('mail address used to send mail')}</Typography>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="email"
						label={this.props.t('email')}
						fullWidth
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						value={this.state.mailSettings?.mail}
						onChange={(e) => this.__onChange("mail", e.target.value)}
						/>
				</Grid>

				<Grid item xs={12} md={6}>
					<TextField
						id="password"
						type="password"
						label={this.props.t('password')}
						fullWidth
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						value={this.state.mailSettings?.password}
						onChange={(e) => this.__onChange("password", e.target.value)}
						/>
				</Grid>

				<Grid item xs={12} md={6}>
					<TextField
						id="service"
						type="service"
						label={this.props.t('service')}
						fullWidth
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						value={this.state.mailSettings?.service}
						onChange={(e) => this.__onChange("service", e.target.value)}
						/>
				</Grid>

				<Grid item xs={12} md={6}>
					<TextField
						id="smtp"
						type="smtp"
						label={this.props.t('smtp')}
						fullWidth
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						value={this.state.mailSettings?.smtp}
						onChange={(e) => this.__onChange("smtp", e.target.value)}
						/>
				</Grid>

				<Grid item xs={12} md={6}>
					<TextField
						id="port"
						type="port"
						label={this.props.t('port')}
						fullWidth
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						value={this.state.mailSettings?.port}
						onChange={(e) => this.__onChange("port", e.target.value)}
						/>
				</Grid>
					

			</Grid>
        );
	}

	__onChange = (field: keyof MailSettings, value: any) => {
		const settings = this.state.mailSettings

		settings[field] = value

		this.setState({ mailSettings: settings})
	}

	__onPressCancel = () => {
		// this.props.history.goBack()
		this.props.history.push(UsersRouter.getRoute())
	}
	__onPressCreate = () => {
		if (!this.state.mailSettings)
			return
		this.setState({information: {
			title: this.props.t('creating'),
			showProgress: true,
			message: `${this.props.t('updating')}...`
		}})
		ServerRequests.setMailSettings(this.state.mailSettings).then((settings) => {
			this.setState({mailSettings: settings, information: {
				title: this.props.t('settings saved'),
				message: this.props.t('settings have been updated'),
				// onClose: () => this.setState({information: undefined})
				onClose: () => this.props.history.push(UsersRouter.getRoute())
			}})
		}).catch((err: GraphQLError) => {
			this.setState({
				information: undefined,
				error: {
					title: this.props.t('error'),
					message: err.message
				}
			})
		})
	}

	__loadSettings = () => {

		this.setState({
			information: {
				title: this.props.t('loading'),
				message: this.props.t('thanks to wait'),
				showProgress: true
			}
		})

		ServerRequests.mailSettings("no-cache").then((settings: MailSettings) => {
			this.setState({information: undefined, mailSettings: {
				mail: settings.mail,
				service: settings.service,
				password: settings.password,
				smtp: settings.smtp,
				port: settings.port,
			}})
		}).catch((err: GraphQLError) => {
			this.setState({
				information: undefined,
				error: {
					title: this.props.t('error'),
					message: err.message
				}
			})
		})
	}

}



export default withTranslation()( withApollo(withRouter(withStyles(styles)(SettingsPage)) as any))