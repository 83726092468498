import { createContext } from 'react';
import { useDispatch } from 'react-redux';
import Snackbar from '../components/extends/Snackbar';
import { SNACKBAR_CLOSE, SNACKBAR_OPEN } from '../store/actions';


//-----------------------|| Snackbar CONTEXT ||-----------------------//

interface SnackbarProps {
	action?: boolean;
	message?: string;
	icon?: JSX.Element;
	href?:   {label: string, url: string};
	actions?: Array<{label: string, action: () => void}>
}

const SnackbarContext = createContext({
	openSuccessSnackbar: (_: string) => Promise.resolve(),
	openInformativeSnackbar: (_: SnackbarProps) => Promise.resolve(),
	openErrorSnackbar: (_: SnackbarProps) => Promise.resolve(),
	closeSnackbar: () => Promise.resolve(),
});

export const SnackbarProvider = ({ children }: { children?: React.ReactElement | React.ReactElement[] }) => {
	const dispatch = useDispatch();

	const openSuccessSnackbar = async (message: string) => {

		dispatch({
			type: SNACKBAR_OPEN,
			open: true,
			message: message,
			variant: 'alert',
			alertSeverity: 'success',
			close: false
		})
	}

	const openErrorSnackbar = async (props: SnackbarProps) => {

		dispatch({
			type: SNACKBAR_OPEN,
			open: true,
			variant: 'alert',
			alertSeverity: 'error',
			...props
		})
	}
	const openInformativeSnackbar = async (props: SnackbarProps) => {

		dispatch({
			type: SNACKBAR_OPEN,
			open: true,
			variant: 'informative',
			// alertSeverity: 'w',
			close: true,
			...props
		})
	}
	
	const closeSnackbar = async () => {

		dispatch({
			type: SNACKBAR_CLOSE,
		})
	}
	return <SnackbarContext.Provider value={{ openSuccessSnackbar, openErrorSnackbar, openInformativeSnackbar, closeSnackbar }}>
		<Snackbar />
		{children}
		</SnackbarContext.Provider>;
};

export default SnackbarContext;
