import React, { Fragment } from 'react';
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import {
	Route,
	// BrowserRouter as Router,
	Switch
} from "react-router-dom";
import { CreateHospitalCenterRouter } from '../../router/dashboard/createHospitalCenter/createHospitalCenter.router';
import { CreatePatientRecordRouter } from '../../router/dashboard/createPatientRecord/createPatientRecord.router';
import { CreateUserRouter } from '../../router/dashboard/createUser/createUser.router';
import { HospitalCentersRouter } from '../../router/dashboard/hospitalCenters/hospitalCenters.router';
import { SettingsRouter } from '../../router/dashboard/settings/settings.router';
import { PatientRecordRouter } from '../../router/dashboard/patientRecord/patientRecord.router';
import { PatientRecordsRouter } from '../../router/dashboard/patientRecords/patientRecords.router';
import { UpdateHospitalCenterRouter } from '../../router/dashboard/updateHospitalCenter/updateHospitalCenter.router';
import { UpdatePatientRecordRouter } from '../../router/dashboard/updatePatientRecord/updatePatientRecord.router';
import { UpdateUserRouter } from '../../router/dashboard/updateUser/updateUsers.router';
import { UsersRouter } from '../../router/dashboard/users/users.router';
import AppBar from './appBar';
import CreateHospitalCenterPage from './createHospitalCenter/createHospitalCenterPage';
import CreatePatientRecordPage from './createPatientRecord/createPatientRecordPage';
import CreateUserPage from './createUser/createUserPage';
import HospitalCentersPage from './hospitalCenters/hospitalCentersPage';
import SettingsPage from './settings/settingsPage';
import UserGuidePage from './user-guide-page';
import PatientRecordPage from './patientRecord/patientRecordPage';
import PatientRecordsPage from './patientRecords/patientRecordsPage';
import SideBar from './sideBar';
import UpdateHospitalCenterPage from './updateHospitalCenter/updateHospitalCenterPage';
import UpdatePatientRecordPage from './updatePatientRecord/updatePatientRecordPage';
import UpdateUserPage from './updateUser/updateUserPage';
import UsersPage from './users/usersPage';
import LogsPage from './logs-page/logsPage';
import { GuideRouter } from '../../router/dashboard/guide/guide.router';
import { ActionLogsRouter } from '../../router/dashboard/logs/action-logs.router';

interface IProps extends RouteComponentProps, RouteComponentProps {
}

interface IState {
	drawerOpen: boolean;
}

class DashboardPage extends React.Component<IProps, IState> {

	constructor(props: IProps) {
		super(props);

		this.state = {

			drawerOpen: false
		}
	}

	componentDidMount = () => {
	}

	render = () => {
		return (
			<Fragment>
				<AppBar onClickMenu={() => this.setState({drawerOpen: !this.state.drawerOpen})} onClickBack={this.__onClickBack}/>
				<Switch>
					<Route path={CreateHospitalCenterRouter.getRoute()} component={CreateHospitalCenterPage}/>
					<Route exact path={CreateUserRouter.getRoute()} component={CreateUserPage}/>
					<Route exact path={CreatePatientRecordRouter.getRoute()} component={CreatePatientRecordPage}/>
					<Route exact path={HospitalCentersRouter.getRoute()} component={HospitalCentersPage}/>
					<Route exact path={PatientRecordRouter.getRoute()} component={PatientRecordPage}/>
					<Route exact path={PatientRecordsRouter.getRoute()} component={PatientRecordsPage}/>
					<Route exact path={UpdateHospitalCenterRouter.getRoute()} component={UpdateHospitalCenterPage}/>
					<Route exact path={UpdateUserRouter.getRoute()} component={UpdateUserPage}/>
					<Route exact path={UpdatePatientRecordRouter.getRoute()} component={UpdatePatientRecordPage}/>
					<Route exact path={UsersRouter.getRoute()} component={UsersPage}/>
					<Route exact path={HospitalCentersRouter.getRoute()} component={HospitalCentersPage}/>
					<Route exact path={SettingsRouter.getRoute()} component={SettingsPage}/>
					<Route exact path={GuideRouter.getRoute()} component={UserGuidePage}/>
					<Route exact path={ActionLogsRouter.getRoute()} component={LogsPage}/>
					<Route>
						<Redirect to={PatientRecordsRouter.getRoute()} />
					</Route>
				</Switch>
				<SideBar open={this.state.drawerOpen} onClose={() => this.setState({drawerOpen: false})}/>
			</Fragment>
		)
	}

	__onClickBack = () => {
		this.props.history.goBack();
	}
}

export default withRouter(DashboardPage);