import { DashboardRouter } from "../dashboard.router";

export class UpdateUserRouter {
    
    static getRoute(idUser?: string): string {
        if (idUser)
            return `${DashboardRouter.getRoute()}/update-user/${idUser}`
        else
            return `${DashboardRouter.getRoute()}/update-user/:id`
    }

}