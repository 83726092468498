import { withApollo, WithApolloClient } from '@apollo/react-hoc'
import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    Select,
    TextField,
    Theme,
    OutlinedInput,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { GraphQLError } from 'graphql'
import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { UserRequests, HospitalCenterRequests } from '../../../apollo'
import CreateSomethingWrapper from '../../../components/createSomethingWrapper'
import ErrorDialog, { IErrorInformation } from '../../../components/errorDialog'
import InformativeDialog, { IInformative } from '../../../components/informativeDialog'
import { HospitalCenter, Roles, User, UserUpdate } from '../../../rebrain-irm-model'
import { UsersRouter } from '../../../router/dashboard/users/users.router'

const styles = ({palette, spacing}: Theme) => createStyles({
	title: {
		fontWeight: 'bolder'
	},
	gradient: {

	}
})

interface IState {
	fieldErrors?:	{[key: string]: string};

	user?: UserUpdate | undefined;

	error?: IErrorInformation;
	information?: IInformative;

	hospitals: Array<HospitalCenter>
}

interface IProps
	extends
	WithStyles<typeof styles>
	, RouteComponentProps<{id: string;}>
	, WithTranslation
	, WithApolloClient<{}>
{

}

class UpdateUserPage extends React.Component<IProps, IState> {

	constructor(props: IProps) {
		super(props);
		this.state = {
			hospitals: []
		}
	}

	componentDidMount = () => {
		this.__loadHospitals()
		this.__loadOriginUser(this.props.match.params.id)
	}

	render = () => {

		return(
			
			<CreateSomethingWrapper
				title={this.props.t('update user')}
				onPressCancel={this.__onPressCancel}
				onPressCreate={this.__onPressCreate}
				>
				{this.__renderForm()}
				<ErrorDialog error={this.state.error} onClose={() => this.setState({error: undefined})}/>
				<InformativeDialog information={this.state.information} onClose={() => {}}/>

			</CreateSomethingWrapper>
		)
	}

	__renderForm = () => {
		return (
            <form>
			<Grid
				item
				container
				spacing={2}
				justifyContent="space-between"
				alignItems="center">

					<Grid item xs={12} sm={6}>	
						<TextField
							id="firstname"
							error={this.state.fieldErrors?.firstname !== undefined}
							helperText={this.state.fieldErrors?.firstname}
							label={this.props.t('firstname')}
							InputLabelProps={{
								shrink: true,
							}}
							fullWidth
							variant="outlined"
							value={this.state.user?.firstname}
							onChange={(e) => this.__onChange("firstname", e.target.value)}
							/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField
							id="lastname"
							error={this.state.fieldErrors?.lastname !== undefined}
							helperText={this.state.fieldErrors?.lastname}
							label={this.props.t('name')}
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							value={this.state.user?.lastname}
							onChange={(e) => this.__onChange("lastname", e.target.value)}
							/>
					</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl variant="outlined" fullWidth error={this.state.fieldErrors?.idHospitalCenter !== undefined}>
						<InputLabel shrink={true} htmlFor="hospital-native-simple">{this.props.t('hospital')}</InputLabel>
						<Select
						native
						variant="outlined"
						value={this.state.user?.idHospitalCenter}
						onChange={(e) => this.__onChange("idHospitalCenter", Number(e.target.value))}

						inputProps={{ id: 'hospital-native-simple'}}
						input={<OutlinedInput notched label={this.props.t('hospital')} />}
						>
							<option value={undefined}></option>
							{
								this.state.hospitals.map((hospital) => <option value={hospital._id}>{hospital.name}</option>)
							}
						</Select>
						<FormHelperText>{this.state.fieldErrors?.idHospitalCenter}</FormHelperText>
					</FormControl>
				</Grid>
				<Grid item xs={1} sm={6}/>
				<Grid item xs={12} sm={6}>
					<FormControl variant="outlined" error={this.state.fieldErrors?.role !== undefined}>
						<InputLabel shrink={true} htmlFor="role-native-simple">{this.props.t('role')}</InputLabel>
						<Select
						native
						variant="outlined"
						value={this.state.user?.role}
						onChange={(e) => this.__onChange("role", e.target.value)}

						inputProps={{ id: 'role-native-simple'}}
						input={<OutlinedInput notched label={this.props.t('role')} />}
						>
							<option value={Roles.ADMIN}>{Roles.ADMIN}</option>
							<option value={Roles.CLINICIAN}>{Roles.CLINICIAN}</option>
							<option value={Roles.OPERATOR}>{Roles.OPERATOR}</option>
						</Select>
						<FormHelperText>{this.state.fieldErrors?.role}</FormHelperText>
					</FormControl>
				</Grid>
				<Grid item xs={1} sm={6}/>

			</Grid>
			</form>
        );
	}

	__onChange = (field: keyof UserUpdate, value: any) => {
		const user = this.state.user;

		if (user) {
			(user[field] as any) = value
			this.setState({user})
		}
	}

	__onPressCancel = () => {
		this.props.history.goBack()
	}
	__onPressCreate = () => {
		const user = this.state.user
		const errors: {[key: string]: string} = {}
		
		if (!user)
			return
		if (!user.role || user.role.length <= 0)
			errors.role = this.props.t('thanks to set a valid role')
		if (!user.firstname || user.firstname.length <= 0)
			errors.firstname = this.props.t('thanks to set a valid firstname')
		if (!user.lastname || user.lastname.length <= 0)
			errors.lastname = this.props.t('thanks to set a valid lastname')
		// if (user.idHospitalCenter === undefined || user.idHospitalCenter === null)
		// 	errors.idHospitalCenter = this.props.t('thanks to set a valid hospital center')

		if (!([Roles.ADMIN, Roles.OPERATOR].includes(user.role!)) && !user.idHospitalCenter)
			errors.idHospitalCenter = this.props.t('thanks to set a valid hospital center')

		if (Object.keys(errors).length > 0)
			return this.setState({fieldErrors: errors})

		this.setState({information: {
			title: this.props.t('updating'),
			showProgress: true,
			message: `${this.props.t('updating')}...`
		}})

		UserRequests.updateUser(this.state.user!)
		.then((_: User) => {
			this.setState({information: {
				title: `${this.props.t('user updated')} !`,
				message: `${this.props.t('user have been updated')}.`,
				onClose: () => this.props.history.push(UsersRouter.getRoute())
			}})
			setTimeout(() => this.props.history.push(UsersRouter.getRoute()), 1500)
		}).catch((error: GraphQLError) => {
			this.setState({information: undefined, error: {
				title: this.props.t('error'),
				message: error.message,
				
			}})
		})
	}

	__loadOriginUser = async (idUser: string) => {
		this.setState({information: {
			title: this.props.t('loading')
			, message: this.props.t('downloading user information')
			, showProgress: true
		}})


		UserRequests.user(idUser, "no-cache")
		.then((user: User) => {
			this.setState({
				information: undefined,
				user: {
					_id: user._id,
					firstname: user.firstname,
					lastname: user.lastname,
					role: user.role,
					idHospitalCenter: user.hospitalCenter ? user.hospitalCenter._id : undefined,
				}
			})
		})
		.catch((err: GraphQLError) => {
			this.setState({information: undefined, error:{
				title: this.props.t('error while loading'),
				message: err.message,
				onClose: this.props.history.goBack
			}})
		})
	}
	__loadHospitals = () => {
		HospitalCenterRequests.hospitalCenters()
		.then((hospitals) => this.setState({hospitals}))
		.catch((error: GraphQLError) => {
			this.setState({information: undefined, error: {
				title: this.props.t('error'),
				message: error.message,
				
			}})
		})
	}
}



export default withTranslation() (withApollo(withRouter(withStyles(styles)(UpdateUserPage)) as any))