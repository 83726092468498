import { FetchPolicy, gql } from "apollo-boost";
import { Client } from ".";
import { MailSettings } from "../rebrain-irm-model";

const QMailSettings = gql`
	query mailSettings {
		mailSettings {
			mail
			password
			service
			smtp
			port
		}
	}
`;

interface MailSettingsData {
	mailSettings: MailSettings;
}


const MSetMailSettings = gql`
	mutation setMailSettings($settings: MailSettingsUpdateSchema!) {
		setMailSettings(settings: $settings) {
			mail
			password
			service
			smtp
			port
		}
	}
`
 interface MSetMailSettingsArgs {
	settings: MailSettings;
}
 interface MSetMailSettingsData {
	setMailSettings: MailSettings;
}


export class ServerRequests {
	public static async setMailSettings(mailSettings: MailSettings): Promise<MailSettings> {
		return (await Client.mutate<MSetMailSettingsData, MSetMailSettingsArgs>({
			mutation: MSetMailSettings,
			variables: {
				settings: mailSettings
			},
		})).data!.setMailSettings
	}

	/**
	 * Get my basic information
	 */
	public static async mailSettings(fetchPolicy?: FetchPolicy): Promise<MailSettings> {
		return (await Client.query<MailSettingsData>({
			query: QMailSettings,
			fetchPolicy
		})).data.mailSettings
	}

	/**
	 * Contact administrators
	 * @param subject Subject of the contact
	 * @param content Content of the contact
	 * @returns True if message is sent
	 */
	public static async contactUs(subject: string, content: string): Promise<boolean> {
		return (await Client.mutate<{contactUs: boolean}, {subject: string, content: string}>({
			mutation: gql`
			mutation contactUs($content: String!, $subject: String!) {
				contactUs(content: $content, subject: $subject)
			}
			`,
			variables: {
				subject, content
			},
		})).data!.contactUs
	}

}