import { Box, Grid, Stack, StackProps, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import banniere from '../../../assets/LOGO COULEUR-300.png';

const useStyles = makeStyles((_: Theme) => ({
	logo: {
		maxWidth: 200
	}
}));

const Section = ({title, children, ...props}: StackProps) => {
	return (
		<Stack {...props}>
			<Typography variant="h3">{title}</Typography>
			{children}
		</Stack>

	)
}
const UserGuidePage = () => {
	const classes = useStyles()
	
	
	return (
		<Grid container justifyContent="center" paddingTop={2}>
			<Grid item xs={12} sm={11} md={10} lg={8}>
				<div id="divToPrint">
					<Stack>
						<Box mx="auto" pb={5}>
							<img src={banniere} alt="logo" className={classes.logo}/>
						</Box>
						<Section title="Section 1">
							<Typography>
							Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
							</Typography>
						</Section>
					</Stack>
				</div>
			</Grid>

		</Grid>
	)
}

export default UserGuidePage