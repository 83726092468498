
// API
// ----------------------------------------------------------------------


// LAYOUT
// ----------------------------------------------------------------------

/** Don't change the unity ! vh must stay vh, number must stay number */
export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------



// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

