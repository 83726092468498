// action - state management
import * as actionTypes from './actions';
import { DefaultRootStateProps } from '../types/index';
import {Reducer, AnyAction} from 'redux'

const initialState: DefaultRootStateProps['app'] = {
};

//-----------------------|| SNACKBAR REDUCER ||-----------------------//

const snackbarReducer: Reducer<DefaultRootStateProps['app']> = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case actionTypes.APP_ACCEPT_COOKIES:
            return {
                ...state,
                isCookieManagementAccepted: true
            };
        default:
            return state;
    }
};


export default snackbarReducer;
