import { FetchPolicy, gql } from "apollo-boost";
import { Client } from ".";
import { ActionLog, ActionLogFilters, FetchingOption, PaginatedResponse } from "../rebrain-irm-model";


const QLogs = gql`
	query actionLogs($options: FetchingActionLogsOptionInput, $conditions: ActionLogFiltersInput) {
		actionLogs(options: $options, conditions: $conditions) {
			totalCount
			hasMore
			items {
				_id
				action
				description
				user {
				_id
				firstname
				lastname
				email
				}
				creationDate
			}
		}
	}
`;

interface LogsData {
	actionLogs:	PaginatedResponse<ActionLog>;
}
interface LogsArgs {
	options?: FetchingOption<ActionLog>;
	conditions?: ActionLogFilters;
}

export class ActionLogRequest {

	/**
	 * Get action logs
	 */
	public static async logs(
		conditions?: ActionLogFilters,
		options?: FetchingOption<ActionLog>,
		fetchPolicy?: FetchPolicy
	): Promise<PaginatedResponse<ActionLog>> {

		return (await Client.query<LogsData, LogsArgs>({
			query: QLogs,
			fetchPolicy,
			variables: {
				options, conditions
			}
		})).data.actionLogs
	}
}