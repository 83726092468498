import { Components, Theme } from '@mui/material/styles';
import { PaletteGreen, PaletteGreenDark, PalettePrimary, PalettePrimaryDark, PalettePrimaryLightLight } from './global.theme';

// project imports

export const componentStyleOverrides = ({palette}: Theme): Components => {
	return {
		MuiListItemText: {
			styleOverrides: {
				root: {
					color: PalettePrimary
				}
			}
		},
		MuiListItem: {
			styleOverrides: {
				root: {
					borderRadius: 8
				}
			}
		},
		MuiContainer: {
			styleOverrides: {
				root: {
				}
			},
		},
		MuiCardMedia: {
			styleOverrides: {
				root: {
					borderRadius: 8,
				}
			}
		},
		MuiCard: {
			styleOverrides: {
				root: {
					padding: 5,
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					padding: 5,
					paddingLeft: 10,
					paddingRight: 10,
				},
				containedPrimary: {
					backgroundColor: PaletteGreen,
					":hover": {
						backgroundColor: PaletteGreenDark
					}
				},
				containedSecondary: {
					backgroundColor: PalettePrimary,
					":hover": {
						backgroundColor: PalettePrimaryDark
					}
				},
			}
		},
		MuiFormControl: {
			styleOverrides: {
				root: {
					backgroundColor: PalettePrimaryLightLight
				}
			}
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					backgroundColor: PalettePrimaryLightLight,
				},
			}
		},
		MuiButtonGroup: {
			styleOverrides: {
				
			}
		},
		MuiAlert: {
			styleOverrides: {
				filledInfo: {
					backgroundColor: PalettePrimary
				}
			}
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					'& .MuiTableCell-root:not(:last-child)': {
						borderRight: "1px solid rgba(224, 224, 224, 1)",
					},
				},
				head: {
					'& .MuiTableCell-root': {
						backgroundColor: palette.grey?.[100]
					}
				}
			}
		},
	};
};
