import { DashboardRouter } from "../dashboard.router";

export class UpdatePatientRecordRouter {
    
    static getRoute(idRecord?: string): string {
        if (idRecord)
            return `${DashboardRouter.getRoute()}/update-patient-record/${idRecord}`
        else
            return `${DashboardRouter.getRoute()}/update-patient-record/:id`
    }

}