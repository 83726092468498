import { withApollo, WithApolloClient } from '@apollo/react-hoc'
import {
	Button,
	ButtonGroup,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	OutlinedInput,
	Select,
	TextField,
	Theme,
	Typography,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DescriptionIcon from '@mui/icons-material/Description'
import Alert from '@mui/material/Alert'
import { GraphQLError } from 'graphql'
import JSZip from 'jszip'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { PatientRecordRequests } from '../../../apollo'
import CreateSomethingWrapper from '../../../components/createSomethingWrapper'
import ErrorDialog, { IErrorInformation } from '../../../components/errorDialog'
import FileListDialog, { FileListDialogProps } from '../../../components/FileListDialog'
import InformativeDialog, { IInformative } from '../../../components/informativeDialog'
import { PatientRecord, PatientRecordPathology, PatientRecordSide, PatientRecordStatus, PatientRecordTarget, PatientRecordTypeOfSurgery, PatientRecordUpdate } from '../../../rebrain-irm-model'
import path from 'path'
import ConfirmationDialog, { ConfirmationInformation } from 'components/confirmationDialog';


const styles = ({ palette, spacing }: Theme) => createStyles({
	circle: {
		display: "inline-block",
		backgroundColor: palette.primary.main,
		borderRadius: "50%",
		width: 5,
		height: 5,
	},
})

interface IState {
	record?: PatientRecordUpdate | undefined;
	original?: PatientRecord | undefined;
	newNoTargetedIrm?: Array<File> | undefined
	newPostOpIrm?: Array<File> | undefined

	fieldErrors?: { [key: string]: string };
	error?: IErrorInformation;
	information?: IInformative;
	fileList?: FileListDialogProps;

	confirmation?: ConfirmationInformation;
}

interface IProps
	extends
	WithStyles<typeof styles>
	, RouteComponentProps<{ id: string; }>
	, WithTranslation
	, WithApolloClient<{}> {

}

class UpdatePatientRecordPage extends React.Component<IProps, IState> {

	_folderInput: HTMLInputElement | null = null;
	_fileInput: HTMLInputElement | null = null;

	_folderPostOpInput: HTMLInputElement | null = null;
	_filePostOpInput: HTMLInputElement | null = null;

	constructor(props: IProps) {
		super(props);
		this.state = {
		}
	}

	componentDidMount = () => {
		this.__loadOriginRecord(this.props.match.params.id)
	}

	setFolderInput = (ref: HTMLInputElement | null) => {
		this._folderInput = ref
		if (this._folderInput) {
			// Allow folder uploads
			this._folderInput.webkitdirectory = true
			this._folderInput.type = "file"
		}
	}
	setFileInput = (ref: HTMLInputElement | null) => {
		this._fileInput = ref
		if (this._fileInput) {
			this._fileInput.type = "file"
		}
	}
	setFolderPostOpInput = (ref: HTMLInputElement | null) => {
		this._folderPostOpInput = ref
		if (this._folderPostOpInput) {
			// Allow folder uploads
			this._folderPostOpInput.webkitdirectory = true
			this._folderPostOpInput.type = "file"
		}
	}
	setFilePostOpInput = (ref: HTMLInputElement | null) => {
		this._filePostOpInput = ref
		if (this._filePostOpInput) {
			this._filePostOpInput.type = "file"
		}
	}

	render = () => {

		return (

			<CreateSomethingWrapper
				title={this.props.t('update patient record')}
				onPressCancel={this.__onPressCancel}
				onPressCreate={this.__onPressCreate}
			>
				{this.__renderForm()}
				<ErrorDialog error={this.state.error} onClose={() => this.setState({ error: undefined })} />
				<InformativeDialog information={this.state.information} onClose={() => { }} />
				<FileListDialog fileListProps={this.state.fileList} onClose={() => this.setState({ fileList: undefined })} />

				<ConfirmationDialog
					confirmation={this.state.confirmation}
					onClose={() => this.setState({ confirmation: undefined })} />
			</CreateSomethingWrapper>
		)
	}

	__renderForm = () => {
		return (
			<form>
				<Grid
					item
					container
					spacing={2}
					justifyContent="space-between"
					alignItems="center">

					{/* <Grid item xs={12} sm={6}>	
						<TextField
							id="patientNumber"
							label="Numéro de patient"
							InputLabelProps={{
								shrink: true,
							}}
							fullWidth
							variant="outlined"
							value={this.state.record?.patientNumber}
							onChange={(e) => this.__onChange("patientNumber", e.target.value)}
							/>
					</Grid>
					<Grid sm={6} xs={12}/> */}

					<Grid item xs={12} sm={6}>
						<TextField
							error={this.state.fieldErrors?.firstname !== undefined}
							helperText={this.state.fieldErrors?.firstname}
							id="firstname"
							label={this.props.t('firstname')}
							InputLabelProps={{
								shrink: true,
							}}
							fullWidth
							variant="outlined"
							value={this.state.record?.firstname}
							onChange={(e) => this.__onChange("firstname", e.target.value)}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField
							error={this.state.fieldErrors?.lastname !== undefined}
							helperText={this.state.fieldErrors?.lastname}
							id="lastname"
							label={this.props.t('name')}
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							value={this.state.record?.lastname}
							onChange={(e) => this.__onChange("lastname", e.target.value)}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<FormControl variant="outlined" fullWidth error={this.state.fieldErrors?.pathologie !== undefined}>
							<InputLabel shrink={true} htmlFor="pathologie-native-simple">{this.props.t('pathology')}</InputLabel>
							<Select
								native

								variant="outlined"
								value={this.state.record?.pathologie}
								onChange={(e) => this.__onChange("pathologie", e.target.value)}

								inputProps={{ id: 'pathologie-native-simple' }}
								input={<OutlinedInput notched label={this.props.t('pathology')} />}
							>
								<option value={undefined}></option>
								<option value={PatientRecordPathology.PARKINSON}>{this.props.t(PatientRecordPathology.PARKINSON)}</option>
								<option value={PatientRecordPathology.ESSENTIAL_TREMOR}>{this.props.t(PatientRecordPathology.ESSENTIAL_TREMOR)}</option>
							</Select>
							<FormHelperText>{this.state.fieldErrors?.pathologie}</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControl variant="outlined" fullWidth error={this.state.fieldErrors?.target !== undefined}>
							<InputLabel shrink={true} htmlFor="target-native-simple">{this.props.t('target')}</InputLabel>
							<Select
								native
								variant="outlined"
								value={this.state.record?.target}
								onChange={(e) => this.__onChange("target", e.target.value)}

								inputProps={{ id: 'target-native-simple' }}
								input={<OutlinedInput notched label={this.props.t('target')} />}
							>
								<option value={undefined}></option>
								{
									this.state.record?.pathologie === PatientRecordPathology.PARKINSON &&
									<option value={PatientRecordTarget.STN}>{this.props.t(PatientRecordTarget.STN)}</option>
								}
								<option value={PatientRecordTarget.VIM}>{this.props.t(PatientRecordTarget.VIM)}</option>
							</Select>
							<FormHelperText>{this.state.fieldErrors?.target}</FormHelperText>
						</FormControl>
					</Grid>

					<Grid item xs={12} sm={6}>
						<FormControl variant="outlined" fullWidth error={this.state.fieldErrors?.side !== undefined}>
							<InputLabel shrink={true} htmlFor="side-native-simple">{this.props.t('side')}</InputLabel>
							<Select
								native

								variant="outlined"
								value={this.state.record?.side}
								onChange={(e) => this.__onChange("side", e.target.value)}

								inputProps={{ id: 'side-native-simple' }}
								input={<OutlinedInput notched label={this.props.t('side')} />}
							>
								<option value={undefined}></option>
								<option value={PatientRecordSide.BILATERAL}>{this.props.t(PatientRecordSide.BILATERAL)}</option>
								<option value={PatientRecordSide.LEFT}>{this.props.t(PatientRecordSide.LEFT)}</option>
								<option value={PatientRecordSide.RIGHT}>{this.props.t(PatientRecordSide.RIGHT)}</option>
							</Select>
							<FormHelperText>{this.state.fieldErrors?.side}</FormHelperText>
						</FormControl>
					</Grid>

					<Grid item xs={12} sm={6}>
						<FormControl variant="outlined" fullWidth error={this.state.fieldErrors?.typeOfSurgery !== undefined}>
							<InputLabel shrink={true} htmlFor="typeOfSurgery-native-simple">{this.props.t('Type of Surgery')}</InputLabel>
							<Select
								native

								variant="outlined"
								value={this.state.record?.typeOfSurgery}
								onChange={(e) => this.__onChange("typeOfSurgery", e.target.value)}

								inputProps={{ id: 'typeOfSurgery-native-simple' }}
								input={<OutlinedInput notched label={this.props.t('Type of Surgery')} />}
							>
								<option value={undefined}></option>
								<option value={PatientRecordTypeOfSurgery.DBS}>{this.props.t(PatientRecordTypeOfSurgery.DBS)}</option>
								<option value={PatientRecordTypeOfSurgery.HIFU}>{this.props.t(PatientRecordTypeOfSurgery.HIFU)}</option>
								<option value={PatientRecordTypeOfSurgery.RADIOSURGERY}>{this.props.t(PatientRecordTypeOfSurgery.RADIOSURGERY)}</option>
							</Select>
							<FormHelperText>{this.state.fieldErrors?.typeOfSurgery}</FormHelperText>
						</FormControl>
					</Grid>

					{/* Pre-op MRI */}
					<Grid item xs={12} container spacing={2}>
						<Grid item xs={12}><Typography variant="h6">{this.props.t('no targeted IRM')}:</Typography></Grid>
						<Grid item>

							<ButtonGroup
								variant="contained" color="secondary">
								<Button
									startIcon={<AddCircleIcon />}
									// component="span"
									onClick={() => this._fileInput && this._fileInput.click()}
								>
									<input
										ref={this.setFileInput}
										onChange={e => this.__onUploadNoTargetedIrm(Array.prototype.slice.call(e.target.files))}
										multiple
										type="file"
										hidden
									/>
									<Typography variant="caption">{this.props.t('new file')}</Typography>
								</Button>
								<Button
									onClick={() => this._folderInput && this._folderInput.click()}
								>
									<input
										ref={this.setFolderInput}
										onChange={e => this.__onUploadNoTargetedIrm(Array.prototype.slice.call(e.target.files))}
										multiple
										type="file"
										hidden
									/>
									<Typography variant="caption">{this.props.t('folder')}</Typography>
								</Button>
							</ButtonGroup>
						</Grid>
						<Grid item>
							<Button
								startIcon={<DescriptionIcon />}
								// href={`${process.env.REACT_APP_SERVER}/patient-record/targeted-irm/${record._id}`}
								variant="contained"
								color="secondary"
								onClick={this.__onClickShowIrm}
								disabled={!this.state.newNoTargetedIrm}>
								<Typography variant="caption">{this.props.t('display files')}</Typography>
							</Button>
						</Grid>
						{
							this.state.newNoTargetedIrm &&
							this.state.newNoTargetedIrm.map(a => a.size).reduce((a, b) => a + b, 0) > Number(process.env.REACT_APP_MAX_UPLOAD_SIZE) &&
							<Grid item xs={12}>
								<Alert severity="error">
									{this.props.t('The data you want to submit exceeds the file size limit of 300MB')}.
								</Alert>
							</Grid>
						}
					</Grid>

					{/* Post-op MRI */}
					<Grid item xs={12} container spacing={2}>
						<Grid item xs={12}><Typography variant="h6">{this.props.t('Post-Operative image')}:</Typography></Grid>
						<Grid item>

							<ButtonGroup
								variant="contained" color="secondary">
								<Button
									startIcon={<AddCircleIcon />}
									// component="span"
									onClick={() => this._filePostOpInput && this._filePostOpInput.click()}
									disabled={
										!this.state.original?.hasPostOpIrm ||
										!this.state.original?.status || ![PatientRecordStatus.GIVE_UP, PatientRecordStatus.POSTOP_TO_PROCESS, PatientRecordStatus.PREOP_MRI_MARKED].includes(this.state.original?.status)
									}
								>
									<input
										ref={this.setFilePostOpInput}
										onChange={e => this.__onUploadPostOpIrm(Array.prototype.slice.call(e.target.files))}
										multiple
										type="file"
										hidden
									/>
									<Typography variant="caption">{this.props.t('new file')}</Typography>
								</Button>
								<Button
									onClick={() => this._folderPostOpInput && this._folderPostOpInput.click()}
									disabled={
										!this.state.original?.hasPostOpIrm ||
										!this.state.original?.status || ![PatientRecordStatus.GIVE_UP, PatientRecordStatus.POSTOP_TO_PROCESS, PatientRecordStatus.PREOP_MRI_MARKED].includes(this.state.original?.status)
									}
								>
									<input
										ref={this.setFolderPostOpInput}
										onChange={e => this.__onUploadPostOpIrm(Array.prototype.slice.call(e.target.files))}
										multiple
										type="file"
										hidden
									/>
									<Typography variant="caption">{this.props.t('folder')}</Typography>
								</Button>
							</ButtonGroup>
						</Grid>
						<Grid item>
							<Button
								startIcon={<DescriptionIcon />}
								// href={`${process.env.REACT_APP_SERVER}/patient-record/targeted-irm/${record._id}`}
								variant="contained"
								color="secondary"
								onClick={this.__onClickShowPostOpIrm}
								disabled={!this.state.newPostOpIrm}>
								<Typography variant="caption">{this.props.t('display files')}</Typography>
							</Button>
						</Grid>
						{
							this.state.newPostOpIrm &&
								this.state.newPostOpIrm.map(a => a.size).reduce((a, b) => a + b, 0) > Number(process.env.REACT_APP_MAX_UPLOAD_SIZE) ?
								<Grid item xs={12}>
									<Alert severity="error">
										{this.props.t('The data you want to submit exceeds the file size limit of 300MB')}.
									</Alert>
								</Grid>
								:
								<Grid item xs={12}>
									<Alert severity="info">
										{this.props.t('The total maximum files size is 300MB')}.
									</Alert>
								</Grid>
						}
					</Grid>

				</Grid>
			</form>
		);
	}

	__onChange = (field: keyof PatientRecordUpdate, value: any) => {
		const record = this.state.record;

		if (record) {
			(record[field] as any) = value
			// Special case
			if (
				record.pathologie === PatientRecordPathology.ESSENTIAL_TREMOR
				&& record.target !== PatientRecordTarget.VIM
			)
				record.target = "" as PatientRecordTarget

			this.setState({ record })
		}
	}

	__onClickShowIrm = () => {
		this.setState({
			fileList: {
				title: this.props.t('files to save'),
				files: this.state.newNoTargetedIrm?.map((f) => f.name)
			}
		})
	}

	__onClickShowPostOpIrm = () => {
		this.setState({
			fileList: {
				title: this.props.t('files to save'),
				files: this.state.newPostOpIrm?.map((f) => f.name)
			}
		})
	}

	__onUploadNoTargetedIrm = (acceptedFiles: File[]) => {
		this.setState({
			confirmation: {
				// title: this.props.t('Files to save')
				message: `${this.props.t('Please be sure that the MRI is pseudonymized before you upload it')}.`
				, onClickAccept: () => {
					this.setState({ confirmation: undefined });
					if (acceptedFiles.length >= 1) {
						this.setState({ newNoTargetedIrm: acceptedFiles })
					}
				}
				, onClickCancel: () => this.setState({ confirmation: undefined })
			}
		})
	}

	__onUploadPostOpIrm = (acceptedFiles: File[]) => {
		this.setState({
			confirmation: {
				// title: this.props.t('Files to save')
				message: `${this.props.t('Please be sure that the MRI is pseudonymized before you upload it')}.`
				, onClickAccept: () => {
					this.setState({ confirmation: undefined });
					if (acceptedFiles.length >= 1) {
						this.setState({ newPostOpIrm: acceptedFiles })
					}
				}
				, onClickCancel: () => this.setState({ confirmation: undefined })
			}
		})
	}

	__onPressCancel = () => {
		this.props.history.goBack()
	}
	__onPressCreate = async () => {
		if (!this.state.record)
			return
		const patientRecord = this.state.record
		const files = this.state.newNoTargetedIrm
		const postOpFiles = this.state.newPostOpIrm
		const errors: { [key: string]: string } = {}

		//Check fields
		if (!patientRecord.firstname || patientRecord.firstname.length <= 0)
			errors.firstname = this.props.t('thanks to set a valid firstname')
		if (!patientRecord.lastname || patientRecord.lastname.length <= 0)
			errors.lastname = this.props.t('thanks to set a valid lastname')

		if (!patientRecord.target || patientRecord.target.trim().length === 0)
			errors.target = this.props.t('thanks to set a valid target')
		if (!patientRecord.pathologie || patientRecord.pathologie.trim().length === 0)
			errors.pathologie = this.props.t('thanks to set a valid pathology')
		if (!patientRecord.side || patientRecord.side.trim().length === 0)
			errors.side = this.props.t('thanks to set a valid side')
		if (!patientRecord.typeOfSurgery || patientRecord.typeOfSurgery.trim().length === 0)
			errors.typeOfSurgery = this.props.t('thanks to set a valid surgery type')

		if (Object.keys(errors).length > 0)
			return this.setState({ fieldErrors: errors })

		if (files && files.map(a => a.size).reduce((a, b) => a + b, 0) > Number(process.env.REACT_APP_MAX_UPLOAD_SIZE)) {
			return this.setState({
				fieldErrors: undefined, error: {
					title: this.props.t('error'),
					message: `${this.props.t('The data you want to submit exceeds the file size limit of 300MB')} !`
				}
			})
		}

		if (postOpFiles && postOpFiles.map(a => a.size).reduce((a, b) => a + b, 0) > Number(process.env.REACT_APP_MAX_UPLOAD_SIZE)) {
			return this.setState({
				fieldErrors: undefined, error: {
					title: this.props.t('error'),
					message: `${this.props.t('The data you want to submit exceeds the file size limit of 300MB')} !`
				}
			})
		}
		this.setState({
			fieldErrors: undefined, information: {
				title: this.props.t('updating'),
				showProgress: true,
				message: `${this.props.t('updating')}...`
			}
		})

		const zipPreOp = async () => {
			const zip = new JSZip();
			if (!files)
				return undefined;

			const isZip = files.length === 1 && files.map(f => path.extname(f.name) === ".zip").reduce((a, b) => a && b, true)

			if (isZip) {
				return files[0]
			} else {
				files.forEach((file) => {
					zip.file(file.name, file)
				})
				return await zip.generateAsync({ type: "blob" })
			}
		}

		const zipPostOp = async () => {
			const zip = new JSZip();
			if (!postOpFiles)
				return undefined;

			const isZip = postOpFiles.length === 1 && postOpFiles.map(f => path.extname(f.name) === ".zip").reduce((a, b) => a && b, true)

			if (isZip) {
				return postOpFiles[0]
			} else {
				postOpFiles.forEach((file) => {
					zip.file(file.name, file)
				})
				console.debug(postOpFiles);
				return await zip.generateAsync({ type: "blob" })
			}
		}

		const push = async () => {
			this.__update(patientRecord, await zipPreOp(), await zipPostOp())
		}
		return push();
	}

	__update = (record: PatientRecordUpdate, irm?: any, postOpIrm?: any) => {
		PatientRecordRequests.updatePatientRecord(record, irm, postOpIrm)
			.then((_) => {
				this.setState({
					information: {
						title: `${this.props.t('patient record updated')} !`,
						message: `${this.props.t('patient record have been updated')}.`,
						onClose: () => this.props.history.goBack()
					},
				})
				setTimeout(this.props.history.goBack, 1500)
			}).catch((err: GraphQLError) => {
				this.setState({
					information: undefined, error: {
						title: this.props.t('error'),
						message: err.message
					}
				})
			})
	}

	__loadOriginRecord = async (idRecord: string) => {
		this.setState({
			information: {
				title: this.props.t('loading')
				, message: this.props.t('downloading patient record')
				, showProgress: true
			}
		})

		PatientRecordRequests.patientRecordWithFieldForUpdate(idRecord, "no-cache")
			.then((record) => {
				this.setState({
					information: undefined,
					record: {
						_id: record._id,
						firstname: record.firstname,
						lastname: record.lastname,
						pathologie: record.pathologie,
						target: record.target,
						side: record.side,
						typeOfSurgery: record.typeOfSurgery,
						status: record.status,
					},
					original: record
				})
			})
			.catch((err: GraphQLError) => {
				this.setState({
					information: undefined, error: {
						title: this.props.t('error while loading'),
						message: err.message
					}
				})
			})
	}
}



export default withTranslation()(withApollo(withRouter(withStyles(styles)(UpdatePatientRecordPage)) as any))