import { combineReducers } from 'redux';
import snackbarReducer from './snackbarReducer';
import appReducer from './appReducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

//-----------------------|| COMBINE REDUCER ||-----------------------//

const reducer = combineReducers({
    snackbar: snackbarReducer,
    app: persistReducer(
        {
            key: 'app',
            storage,
            keyPrefix: 'rebrain-'
        },
        appReducer
    ),
});

export default reducer;
