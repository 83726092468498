import { FetchPolicy } from "@apollo/react-hoc";
import { gql } from "apollo-boost";
import JSZip from 'jszip';
import { Client } from ".";
import { PatientRecord, PatientRecordCreate, PatientRecordUpdate } from "../rebrain-irm-model";

export const MCreatePatientRecord = gql`
	mutation createPatientRecord($createHospital: PatientRecordCreateSchema!, $originalIrm: Upload) {
		createPatientRecord(patientRecord: $createHospital, originalIrm: $originalIrm) {
			_id
		}
	}
`;

interface MCreatePatientRecordArgs
{
	createHospital:	PatientRecordCreate;

	originalIrm: any
}

interface MCreatePatientRecordData
{
	createPatientRecord:	PatientRecord;
}

export const QPatientRecords = gql`
	query patientRecords {
		patientRecords {
			_id
			firstname
			lastname
			patientNumber
			pathologie
			target
			side
			typeOfSurgery
			status
			clinicianDowloadedDate
			creationDate
			treatmentDate
			clinician {
				firstname
				lastname
				hospitalCenter {
					name
				}
			}
		}
	}
`;

interface QPatientRecordsData
{
	patientRecords:	Array<PatientRecord>;
}

export const QPatientRecord = gql`
	query patientRecord($idPatientRecord: String!) {
		patientRecord(idPatientRecord: $idPatientRecord) {
			_id
			firstname
			lastname
			patientNumber
			pathologie
			target
			side
			typeOfSurgery
			status
			hasNoTargetedIrm
			hasTargetedIrm
			hasTargetedPostOpIrm
			hasPostOpIrm
			clinicianDowloadedDate
			treatmentDate
			creationDate
			clinician {
				firstname
				lastname
				hospitalCenter {
					name
				}
			}
			giveUpReason
		}
	}
`;

interface QPatientRecordData
{
	patientRecord:	PatientRecord;
}
interface QPatientRecordArgs
{
	idPatientRecord:	string;
}

export const QPatientRecordWithFieldForUpdate = gql`
	query patientRecord($idPatientRecord: String!) {
		patientRecord(idPatientRecord: $idPatientRecord) {
			_id
			firstname
			lastname
			pathologie
			target
			side
			typeOfSurgery
			status
			hasPostOpIrm
		}
	}
`;
export const MRemovePatientRecord = gql`
	mutation removePatientRecord($idPatientRecord: String!){
		removePatientRecord(idPatientRecord: $idPatientRecord) {
			patientNumber
		}
	}
`;

interface MRemovePatientRecordArgs
{
	idPatientRecord:	string;
}

interface MRemovePatientRecordData
{
	removePatientRecord:	PatientRecord;
}

export const MRejectPatientRecord = gql`
	mutation rejectPatientRecord($idPatientRecord: String!, $reason: String!){
		rejectPatientRecord(idPatientRecord: $idPatientRecord, reason: $reason) {
			patientNumber
		}
	}
`;

interface MRejectPatientRecordArgs
{
	idPatientRecord		:	string;

	reason				: string
}

interface MRejectPatientRecordData
{
	rejectPatientRecord:	PatientRecord;
}

export const MUpdatePatientRecord = gql`
	mutation updatePatientRecord($updateHospital: PatientRecordUpdateSchema!, $originalIrm: Upload, $postOpIrm: Upload) {
		updatePatientRecord(patientRecord: $updateHospital, originalIrm: $originalIrm, postOpIrm: $postOpIrm) {
			_id
		}
	}
`;

interface MUpdatePatientRecordArgs
{
	updateHospital:	PatientRecordUpdate;

	originalIrm: any;

	postOpIrm: any;
}

interface MUpdatePatientRecordData
{
	updatePatientRecord:	PatientRecord;
}

// export const MSetOriginalIrm = gql`
// 	mutation setOriginalIrm($irm: PatientRecordSetIrmSchema!){
// 		setOriginalIrm(irm: $irm) {
// 			_id
// 		}
// 	}
// `
export const MSetOriginalIrm = gql`
	mutation setOriginalIrm($idRecord: String!, $irm: Upload!){
		setOriginalIrm(idRecord: $idRecord, irm: $irm) {
			_id
		}
	}
`

export const MSetTargetedIrm = gql`
	mutation setTargetedIrm($idRecord: String!, $irm: Upload!){
		setTargetedIrm(idRecord: $idRecord, irm: $irm) {
			_id
		}
	}
`
export const MSetPostOpIrm = gql`
	mutation setPostOpIrm($idRecord: String!, $irm: Upload!){
		setPostOpIrm(idRecord: $idRecord, irm: $irm) {
			_id
		}
	}
`

export const MSetTargetedPostOpIrm = gql`
	mutation setTargetedPostOpIrm($idRecord: String!, $irm: Upload!){
		setTargetedPostOpIrm(idRecord: $idRecord, irm: $irm) {
			_id
		}
	}
`

interface MSetOriginalIrmArgs
{
		idRecord		:	string

		irm		:	any
}

export const MSendEndOfProcessingMail = gql`
	mutation sendEnOfProcessingMail($idRecord: String!) {
		sendEndOfProcessingMail(idRecord: $idRecord)
	}
`;
export const MSendEndOfPostOpProcessingMail = gql`
	mutation sendEnOfPostOpProcessingMail($idRecord: String!) {
		sendEndOfPostOpProcessingMail(idRecord: $idRecord)
	}
`;

interface MSendEndOfProcessingMailArgs
{
	idRecord:	string
}

interface MSendEndOfProcessingMailData
{
	sendEndOfProcessingMail: boolean
}
interface MSendEndOfPostOpProcessingMailData
{
	sendEndOfPostOpProcessingMail: boolean
}
export class PatientRecordRequests {

	
	public static async createPatientRecord(createHospital: PatientRecordCreate, originalIrm: any): Promise<PatientRecord> {
		return (await Client.mutate<MCreatePatientRecordData, MCreatePatientRecordArgs>({
			mutation: MCreatePatientRecord,
			variables: {
				createHospital,
				originalIrm
			},
		})).data!.createPatientRecord
	}

	public static async patientRecords(fetchPolicy?: FetchPolicy): Promise<Array<PatientRecord>> {
		return (await Client.query<QPatientRecordsData>({
			query: QPatientRecords,
			fetchPolicy
		})).data.patientRecords
	}

	public static async patientRecord(idPatientRecord: string, fetchPolicy?: FetchPolicy): Promise<PatientRecord> {
		return (await Client.query<QPatientRecordData, QPatientRecordArgs>({
			query: QPatientRecord,
			fetchPolicy,
			variables: {
				idPatientRecord
			}
		})).data.patientRecord
	}

	public static async patientRecordWithFieldForUpdate(idPatientRecord: string, fetchPolicy?: FetchPolicy): Promise<PatientRecord> {
		return (await Client.query<QPatientRecordData, QPatientRecordArgs>({
			query: QPatientRecordWithFieldForUpdate,
			fetchPolicy,
			variables: {
				idPatientRecord
			}
		})).data.patientRecord
	}

	public static async removePatientRecord(idPatientRecord: string): Promise<PatientRecord> {
		return (await Client.mutate<MRemovePatientRecordData, MRemovePatientRecordArgs>({
			mutation: MRemovePatientRecord,
			variables: {
				idPatientRecord
			},
		})).data!.removePatientRecord
	}
	public static async rejectPatientRecord(idPatientRecord: string, reason: string): Promise<PatientRecord> {
		return (await Client.mutate<MRejectPatientRecordData, MRejectPatientRecordArgs>({
			mutation: MRejectPatientRecord,
			variables: {
				idPatientRecord,
				reason
			},
		})).data!.rejectPatientRecord
	}

	public static async updatePatientRecord(updateHospital: PatientRecordUpdate, originalIrm?: any, postOpIrm?: any): Promise<PatientRecord> {
		return (await Client.mutate<MUpdatePatientRecordData, MUpdatePatientRecordArgs>({
			mutation: MUpdatePatientRecord,
			variables: {
				updateHospital,
				originalIrm,
				postOpIrm
			},
		})).data!.updatePatientRecord
	}

	public static async setOriginalIrm(idRecord: string, irm: any): Promise<PatientRecord> {
		return (await Client.mutate<any, MSetOriginalIrmArgs>({
			mutation: MSetOriginalIrm,
			variables: {
					idRecord: idRecord,
					irm: irm
			},
		})).data!.updatePatientRecord
	}

	public static async setTargetedIrm(idRecord: string, irm: any): Promise<PatientRecord> {
		return (await Client.mutate<any, MSetOriginalIrmArgs>({
			mutation: MSetTargetedIrm,
			variables: {
					idRecord: idRecord,
					irm: irm
			},
		})).data!.updatePatientRecord
	}

	public static async setPostOpIrm(idRecord: string, irm: any): Promise<PatientRecord> {
		return (await Client.mutate<any, MSetOriginalIrmArgs>({
			mutation: MSetPostOpIrm,
			variables: {
					idRecord: idRecord,
					irm: irm
			},
		})).data!.updatePatientRecord
	}

	public static async setTargetedPostOpIrm(idRecord: string, irm: any): Promise<PatientRecord> {
		return (await Client.mutate<any, MSetOriginalIrmArgs>({
			mutation: MSetTargetedPostOpIrm,
			variables: {
					idRecord: idRecord,
					irm: irm
			},
		})).data!.updatePatientRecord
	}

	public static async downloadOriginalIrmFileNames(recordId: string): Promise<Array<string>> {
		var zip = new JSZip();
		
		const response = await fetch(`${process.env.REACT_APP_SERVER}/patient-record/original-irm/${recordId}`, {credentials: "include"})
		const blob = await response.blob()
		const content = await zip.loadAsync(blob)
		console.log(Object.keys(content.files))
		return Object.keys(content.files)
	}

	public static async downloadTargetedIrmFileNames(recordId: string): Promise<Array<string>> {
		var zip = new JSZip();
		
		const response = await fetch(`${process.env.REACT_APP_SERVER}/patient-record/targeted-irm/${recordId}`, {credentials: "include"})
		const blob = await response.blob()
		const content = await zip.loadAsync(blob)
		console.log(Object.keys(content.files))
		return Object.keys(content.files)
	}
	
	public static async downloadPostOpIrmFileNames(recordId: string): Promise<Array<string>> {
		var zip = new JSZip();
		
		const response = await fetch(`${process.env.REACT_APP_SERVER}/patient-record/postop-original-irm/${recordId}`, {credentials: "include"})
		const blob = await response.blob()
		const content = await zip.loadAsync(blob)
		console.log(Object.keys(content.files))
		return Object.keys(content.files)
	}
	public static async downloadTargetedPostOpIrmFileNames(recordId: string): Promise<Array<string>> {
		var zip = new JSZip();
		
		const response = await fetch(`${process.env.REACT_APP_SERVER}/patient-record/postop-targeted-irm/${recordId}`, {credentials: "include"})
		const blob = await response.blob()
		const content = await zip.loadAsync(blob)
		console.log(Object.keys(content.files))
		return Object.keys(content.files)
	}

	public static async sendEndOfProcessingMail(idRecord: string): Promise<boolean> {
		return (await Client.mutate<MSendEndOfProcessingMailData, MSendEndOfProcessingMailArgs>({
			mutation: MSendEndOfProcessingMail,
			variables: {
				idRecord
			},
		})).data!.sendEndOfProcessingMail
	}

	public static async sendEndOfPostOpProcessingMail(idRecord: string): Promise<boolean> {
		return (await Client.mutate<MSendEndOfPostOpProcessingMailData, MSendEndOfProcessingMailArgs>({
			mutation: MSendEndOfPostOpProcessingMail,
			variables: {
				idRecord
			},
		})).data!.sendEndOfPostOpProcessingMail
	}
}