import { DashboardRouter } from "../dashboard.router";

export class PatientRecordRouter {
    
    static getRoute(idRecord?:  string): string {
        if (idRecord)
            return `${DashboardRouter.getRoute()}/patient-record/${idRecord}`
        else
            return `${DashboardRouter.getRoute()}/patient-record/:id`
    }

}