import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DescriptionIcon from '@mui/icons-material/Description';
import GppGoodIcon from '@mui/icons-material/GppGood';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
	Box,
	Button,
	Divider,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Paper,
	Select,
	Theme,
	Typography
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';
import { UploadButtonGroup } from 'components/UploadButtonGroup';
import { GraphQLError } from 'graphql';
import JSZip from 'jszip';
import path from 'path';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { PatientRecordRequests, UserRequests } from '../../../apollo';
import ConfirmationDialog, { ConfirmationInformation } from '../../../components/confirmationDialog';
import ErrorDialog, { IErrorInformation } from '../../../components/errorDialog';
import FileListDialog, { FileListDialogProps } from '../../../components/FileListDialog';
import InformativeDialog, { IInformative } from '../../../components/informativeDialog';
import useSnackbar from '../../../hooks/useSnackbar';
import { PatientRecord, PatientRecordStatus, Roles, User } from '../../../rebrain-irm-model';
import { PatientRecordsRouter } from '../../../router/dashboard/patientRecords/patientRecords.router';
import { UpdatePatientRecordRouter } from '../../../router/dashboard/updatePatientRecord/updatePatientRecord.router';
import FormatToolbox from '../../../toolbox/FormatToolbox';

const useStyles = makeStyles(({ palette }: Theme) => ({
	circle: {
		display: "inline-block",
		backgroundColor: palette.primary.main,
		borderRadius: "50%",
		width: 5,
		height: 5,
	},
}));


const PatientRecordPage = () => {
	// Hooks
	const params = useParams<{ id: string }>()
	const classes = useStyles()
	const history = useHistory()
	const { openInformativeSnackbar } = useSnackbar()
	const { formatMessage } = useIntl()
	const { t } = useTranslation()

	/** States */
	const [user, setUser] = useState<User>()
	const [record, setRecord] = useState<PatientRecord>()
	const [error, setError] = useState<IErrorInformation>()
	const [information, setInformation] = useState<IInformative>()
	const [confirmation, setConfirmation] = useState<ConfirmationInformation>()
	const [newTargetedIrm, setNewTargetedIrm] = useState<Array<File>>()
	const [anchorMenu, setAnchorMenu] = useState<any>()
	// post op
	const [newPostOpIrm, setNewPostOpIrm] = useState<Array<File>>()
	const [newTargetedPostOpIrm, setNewTargetedPostOpIrm] = useState<Array<File>>()

	// States to show files in dialog
	const [fileListProps, setFileListProps] = useState<FileListDialogProps>() /** Use to render dialog with file list */
	const [fileListTargetedProps, setFileListTargetedProps] = useState<FileListDialogProps>() /** Use to render dialog with file list */

	// Effects
	useEffect(() => {
		setInformation({
			title: formatMessage({ id: 'loading' }),
			showProgress: true
		})
		setTimeout(() => {
			__loadCurrentUser()
			__loadPatientRecord(params.id)
		}, 1000)
	}, [params])
	// Methods

	const __renderContent = () => {
		if (!record)
			return null
		return (
			<Grid container>
				<Grid item container xs={12} spacing={3} alignItems="center">
					<Grid item sm={5}><Typography variant="h6">{t('creation date')}:</Typography></Grid>

					<Grid item>
						<Typography variant="body1">
							{
								record.creationDate &&
								<FormattedDate day="2-digit" month="long" year="numeric" value={record.creationDate} />
							}
						</Typography>
					</Grid>
				</Grid>
				<Grid item container xs={12} spacing={3} alignItems="center">
					<Grid item sm={5}><Typography variant="h6">{t('status')}:</Typography></Grid>
					<Grid item>
						{
							record && user && [Roles.ADMIN, Roles.OPERATOR].includes(user.role) ?
								<Grid item>
									<Select
										size="small"
										value={record?.status}
										onChange={e => __updateStatus(e.target.value as PatientRecordStatus)}>
										{
											Object.values(PatientRecordStatus).map((value) => (
												<MenuItem key={value} value={value}><FormattedMessage id={FormatToolbox.patientRecordEnumToString(value)} /></MenuItem>
											))
										}
									</Select>
								</Grid>
								:
								<Typography variant="body1"><FormattedMessage id={FormatToolbox.patientRecordEnumToString(record.status)} /></Typography>
						}
					</Grid>
				</Grid>
				{
					record.status === PatientRecordStatus.GIVE_UP &&
					<Grid item container xs={12} spacing={3} alignItems="center">
						<Grid item sm={5}><Typography variant="h6">{t('reject reason')}:</Typography></Grid>
						<Grid item><Typography variant="body1">{record.giveUpReason}</Typography></Grid>
					</Grid>
				}
				<Grid item container xs={12} spacing={3} alignItems="center">
					<Grid item sm={5}><Typography variant="h6">
						{
							user?.role === Roles.CLINICIAN ?
								t('name')
								: t('clinician')
						}
						:</Typography></Grid>
					<Grid item><Typography variant="body1">
						{
							user?.role === Roles.CLINICIAN ?
								`${record.firstname} ${record.lastname}`
								: `${record.clinician?.firstname} ${record.clinician?.lastname} ${record.clinician && record.clinician.hospitalCenter ? `(${record.clinician.hospitalCenter?.name})` : ''}`
						}
					</Typography></Grid>
				</Grid>
				<Grid item container xs={12} spacing={3} alignItems="center">
					<Grid item sm={5} ><Typography variant="h6">{t('pathology')}:</Typography></Grid>
					<Grid item><Typography variant="body1">{record.pathologie && t(record.pathologie)}</Typography></Grid>
				</Grid>
				<Grid item container xs={12} spacing={3} alignItems="center">
					<Grid item sm={5}><Typography variant="h6">{t('target')}:</Typography></Grid>
					<Grid item><Typography variant="body1">{record.target && t(record.target)}</Typography></Grid>
				</Grid>
				<Grid item container xs={12} spacing={3} alignItems="center">
					<Grid item sm={5}><Typography variant="h6">{t('side')}:</Typography></Grid>
					<Grid item><Typography variant="body1">{record.side && t(record.side)}</Typography></Grid>
				</Grid>
				<Grid item container xs={12} spacing={3} alignItems="center">
					<Grid item sm={5}><Typography variant="h6">{t('typeOfSurgery')}:</Typography></Grid>
					<Grid item><Typography variant="body1">{record.typeOfSurgery && t(record.typeOfSurgery)}</Typography></Grid>
				</Grid>

				<Grid item container xs={12} spacing={3} alignItems="center">
					<Grid item sm={5}><Typography variant="h6">{t('processing date')}:</Typography></Grid>
					<Grid item><Typography variant="body1">{
						record.treatmentDate ?
							<FormattedDate value={record.treatmentDate} day="numeric" month="long" year="numeric" />
							:
							"-"
					}</Typography></Grid>
				</Grid>
				<Grid item container xs={12} spacing={3} alignItems="center">
					<Grid item sm={5}><Typography variant="h6">{t('First targeted MRI download')}:</Typography></Grid>
					<Grid item><Typography variant="body1">{
						record.clinicianDowloadedDate ?
							<FormattedDate value={record.clinicianDowloadedDate} day="numeric" month="long" year="numeric" />
							:
							"-"
					}</Typography></Grid>
				</Grid>
			</Grid>
		)
	}

	const __renderPreOperativeMRI = () => {
		if (!record)
			return null
		return (

			<Grid
				item
				container
				spacing={1}
			>
				<Grid item xs={12}><Typography variant="h5">{t('Pre-Operative Data')}</Typography></Grid>
				{/* IRM Non ciblé */}
				<Grid item xs={12} container spacing={2}>
					<Grid item sm={5}><Typography variant="h6">{t('no targeted IRM')}:</Typography></Grid>
					<Grid item>
						<Button
							onClick={__onClickShowOriginalIrmFiles}
							variant="contained"
							color="secondary"
							startIcon={<DescriptionIcon />}
							disabled={!record.hasNoTargetedIrm}>
							<Typography variant="caption">{t('display files')}</Typography>
						</Button>
					</Grid>
				</Grid>

				{/* IRM ciblé */}
				<Grid item xs={12} container spacing={2}>
					<Grid item sm={5}><Typography variant="h6">{t('targeted IRM')}:</Typography></Grid>
					<Grid item>
						<Button
							startIcon={<DescriptionIcon />}
							variant="contained"
							color="secondary"
							onClick={__onClickShowTargetedIrmFiles}
							disabled={!record.hasTargetedIrm}>
							<Typography variant="caption">{t('display files')}</Typography>
						</Button>
					</Grid>

					{
						user && [Roles.ADMIN, Roles.OPERATOR].includes(user.role) &&
						<Grid item>
							<UploadButtonGroup
								disabled={![PatientRecordStatus.IN_PROGRESS, PatientRecordStatus.TO_DO].includes(record?.status)}
								onUpload={__onUploadTargetedIrm}/>
						</Grid>
					}
					{
						newTargetedIrm &&
						newTargetedIrm.map(a => a.size).reduce((a, b) => a + b, 0) > Number(process.env.REACT_APP_MAX_UPLOAD_SIZE) &&
						<Grid item xs={12}>
							<Alert severity="error">
								{t('The total maximum files size is 300mb')}.
							</Alert>
						</Grid>
					}
					<Grid item xs={12}>
						{__renderFileList(newTargetedIrm, __onClickSaveNewTargetedFile)}
					</Grid>
				</Grid>

			</Grid>
		)
	}

	const __renderPostOperativeMRI = () => {
		if (!record)
			return null
		return (

			<Grid
				item
				container
				spacing={1}
			>
				<Grid item xs={12}><Typography variant="h5">{t('Post-Operative Data')}</Typography></Grid>
				{/* IRM Non ciblé */}
				<Grid item xs={12} container spacing={2}>
					<Grid item sm={5}><Typography variant="h6">{t('Post-Operative image')}:</Typography></Grid>
					<Grid item>
						<Button
							onClick={__onClickShowPostOpIrmFiles}
							variant="contained"
							color="secondary"
							startIcon={<DescriptionIcon />}
							disabled={!record.hasPostOpIrm}
						>
							<Typography variant="caption">{t('display files')}</Typography>
						</Button>
					</Grid>
					{
						user && [Roles.CLINICIAN].includes(user.role) &&
						<Grid item>
							<UploadButtonGroup
								onUpload={__onUploadPostOpIrm}
								disabled={![PatientRecordStatus.PREOP_MRI_MARKED, PatientRecordStatus.DOWNLOADED].includes(record.status)}/>
						</Grid>
					}
					{
						newPostOpIrm &&
						newPostOpIrm.map(a => a.size).reduce((a, b) => a + b, 0) > Number(process.env.REACT_APP_MAX_UPLOAD_SIZE) &&
						<Grid item xs={12}>
							<Alert severity="error">
								{t('The total maximum files size is 300mb')}.
							</Alert>
						</Grid>
					}
					<Grid item xs={12}>
						{
							__renderFileList(
								newPostOpIrm,
								files => __onClickSaveNewFile(files, "postop").then(() => {
									setNewPostOpIrm(undefined)
									__loadPatientRecord(record!._id)
								})
							)}
					</Grid>
				</Grid>

				{/* IRM ciblé */}
				<Grid item xs={12} container spacing={2}>
					<Grid item sm={5}><Typography variant="h6">{t('Post-operative checking')}:</Typography></Grid>
					<Grid item>
						<Button
							startIcon={<DescriptionIcon />}
							variant="contained"
							color="secondary"
							onClick={__onClickShowTargetedPostOpIrmFiles}
							disabled={!record.hasTargetedPostOpIrm}
						>
							<Typography variant="caption">{t('display files')}</Typography>
						</Button>
					</Grid>

					{
						user && [Roles.ADMIN, Roles.OPERATOR].includes(user.role) &&
						<Grid item>
							<UploadButtonGroup onUpload={__onUploadTargetedPostOpIrm}/>
						</Grid>
					}
					{
						newTargetedPostOpIrm &&
						newTargetedPostOpIrm.map(a => a.size).reduce((a, b) => a + b, 0) > Number(process.env.REACT_APP_MAX_UPLOAD_SIZE) &&
						<Grid item xs={12}>
							<Alert severity="error">
								{t('The total maximum files size is 300mb')}.
							</Alert>
						</Grid>
					}
					<Grid item xs={12}>
						{
							__renderFileList(
								newTargetedPostOpIrm,
								files => __onClickSaveNewFile(files, "targeted-postop").then(() => {
									setNewTargetedPostOpIrm(undefined)
									__loadPatientRecord(record!._id)
								})
							)}
					</Grid>
				</Grid>

			</Grid>
		)
	}

	const __renderPreOpMRIProcess = () => {
		if (
			!record?.hasTargetedIrm ||
			user?.role === Roles.CLINICIAN ||
			![PatientRecordStatus.IN_PROGRESS, PatientRecordStatus.TO_DO].includes(record?.status) ||
			newTargetedIrm
		)
		// if (!record || !record.hasTargetedIrm || !user || user.role === Roles.CLINICIAN)
			return null

		return (
			<Grid item xs={12} container>
				<Button
					startIcon={<CheckCircleOutlineIcon />}
					variant="contained"
					color="secondary"
					onClick={() => setConfirmation({
						title: t('validate')
						, message: `${t('are you sure to validate the patient record ?')} ${t('a mail will be send to the clinician')}.`
						, onClickAccept: () => { setConfirmation(undefined); __onClickSendEndOfPreOp(); }
						, onClickCancel: () => setConfirmation(undefined)
					})}
				>
					{t('validate')}
				</Button>
			</Grid>
		)
	}

	const __renderPostOpMRIProcess = () => {
		if (
			!record?.hasTargetedPostOpIrm ||
			user?.role === Roles.CLINICIAN ||
			![PatientRecordStatus.POSTOP_TO_PROCESS, PatientRecordStatus.IN_PROGRESS].includes(record?.status) ||
			newTargetedPostOpIrm
		)
			return null

		return (
			<Grid item xs={12} container>
				<Button
					startIcon={<CheckCircleOutlineIcon />}
					variant="contained"
					color="secondary"
					onClick={() => setConfirmation({
						title: t('validate & finish')
						, message: `${t('are you sure to validate and finish the patient record ?')} ${t('a mail will be send to the clinician')}.`
						, onClickAccept: () => { setConfirmation(undefined); __onClickSendEndOfPostOp(); }
						, onClickCancel: () => setConfirmation(undefined)
					})}
				>
					{t('validate & finish')}
				</Button>
			</Grid>
		)
	}
	const __renderFileList = (files?: Array<File>, onSave?: (files: Array<File>) => void) => {
		if (!files)
			return null
		const f = files?.map((file) => {
			return (
				<Grid item xs={12} container spacing={2}>
					<Grid item><Box className={classes.circle} /></Grid>
					<Grid item><Typography>{file.name}</Typography></Grid>

				</Grid>
			)
		})

		return (
			<Grid container>
				{f}
				<Button
					startIcon={<CloudUploadIcon />}
					variant="contained"
					color="secondary"
					onClick={() => onSave && onSave(files)}>
					<Typography>{t('save')}</Typography>
				</Button>
			</Grid>
		)
	}

	const __renderTitle = () => {
		return (
			<Grid item xs={12} container component={Box} px={2} py={1} justifyContent="space-between">
				<Grid item>
					<Typography variant="h3" color="primary">{t('patient record')} {record ? record.patientNumber : undefined}</Typography>
				</Grid>
				{
					user &&
					// user.role !== Roles.OPERATOR &&
					<Grid item>
						<IconButton
							onClick={(e) => setAnchorMenu(e.currentTarget)}
							size="large">
							<MoreVertIcon />
						</IconButton>
						<Menu
							id="simple-menu"
							anchorEl={anchorMenu}
							keepMounted
							open={anchorMenu !== undefined}
							onClose={() => setAnchorMenu(undefined)}
							onClick={() => setAnchorMenu(undefined)}
						>
							{
								user.role === Roles.CLINICIAN &&
								<MenuItem onClick={() => __onClickUpdateRecord()}>{t('update')}</MenuItem>
							}
							{
								[Roles.CLINICIAN, Roles.ADMIN, Roles.OPERATOR].includes(user.role) &&
								<MenuItem onClick={() => setConfirmation({
									title: t('delete')
									, message: t('are you sure to delete this patient record ?')
									, onClickAccept: () => { setConfirmation(undefined); __onClickRemoveRecord() }
									, onClickCancel: () => setConfirmation(undefined)
								})}>{t('delete')}</MenuItem>
							}

							{
								user.role !== Roles.CLINICIAN &&
								<MenuItem onClick={__onClickRecordReject}>{t('reject')}</MenuItem>
							}
						</Menu>
					</Grid>
				}
			</Grid>
		);
	}

	const __loadCurrentUser = () => {
		UserRequests.meBasic().then((user) => {
			setUser(user)
		}).catch(() => { })
	}
	const __loadPatientRecord = (id: string) => {
		setInformation({
			title: t('loading'),
			showProgress: true
		})
		PatientRecordRequests.patientRecord(id, "no-cache")
			.then((record) => {
				setRecord(record)
			}).catch((err: GraphQLError) => {
				setError({
					title: t('error'),
					message: err.message
				})
			}).finally(() => setInformation(undefined))
	}

	// __onUploadNoTargetedIrm = (acceptedFiles: File[], rejectedFiles: FileRejection[], event: DropEvent) => {
	// 	if (acceptedFiles.length >= 1) {
	// 		setState({ newNoTargetedIrm: acceptedFiles })
	// 	}
	// }

	const __onUploadTargetedIrm = (acceptedFiles: File[]) => {
		setNewTargetedIrm(acceptedFiles)
	}

	const __onUploadTargetedPostOpIrm = (acceptedFiles: File[]) => {
		setNewTargetedPostOpIrm(acceptedFiles)
	}

	const __onUploadPostOpIrm = (acceptedFiles: File[]) => {
		setNewPostOpIrm(acceptedFiles)
	}

	const __onClickSaveNewTargetedFile = (files: Array<File>) => {
		if (files && files.map(a => a.size).reduce((a, b) => a + b, 0) > Number(process.env.REACT_APP_MAX_UPLOAD_SIZE))
			return setError({
				title: t('error'),
				message: `${t('The data you want to submit exceeds the file size limit of 300MB')} !`
			})

		const zip = new JSZip()

		setInformation({
			title: t('uploading'),
			message: t('saving new files'),
			showProgress: true
		})


		const push = async () => {
			const isZip = files.length === 1 && files.map(f => path.extname(f.name) === ".zip").reduce((a, b) => a && b, true)
			let file;

			if (isZip) {
				file = files[0]
			} else {
				files.forEach((file) => {
					zip.file(file.name, file)
				})
				file = await zip.generateAsync({ type: "blob" })
			}

			await PatientRecordRequests.setTargetedIrm(record!._id, file)
		}

		push()
			.then(() => {
				setInformation(undefined)
				setNewTargetedIrm(undefined)
				__loadPatientRecord(record!._id)
			})
			.catch(err => {
				setInformation(undefined)
				setError({
					title: t('error'),
					message: err.message
				})
			})
			.finally(() => {
				setInformation(undefined)
			})
	}


	const __onClickSaveNewFile = async (files: Array<File>, method: string): Promise<void> => {

		const methods: { [K: string]: Function } = {
			"targeted": PatientRecordRequests.setTargetedIrm,
			"original": PatientRecordRequests.setOriginalIrm,
			"postop": PatientRecordRequests.setPostOpIrm,
			"targeted-postop": PatientRecordRequests.setTargetedPostOpIrm,
		}
		if (!Object.keys(methods).includes(method)) {
			throw new Error("Unknown method to save files")
		}

		if (files && files.map(a => a.size).reduce((a, b) => a + b, 0) > Number(process.env.REACT_APP_MAX_UPLOAD_SIZE)) {
			return setError({
				title: t('error'),
				message: `${t('The data you want to submit exceeds the file size limit of 300MB')} !`
			})
		}

		const zip = new JSZip()

		setInformation({
			title: t('uploading'),
			message: t('saving new files'),
			showProgress: true
		})


		const push = async () => {
			const isZip = files.length === 1 && files.map(f => path.extname(f.name) === ".zip").reduce((a, b) => a && b, true)
			let file;

			if (isZip) {
				file = files[0]
			} else {
				files.forEach((file) => {
					zip.file(file.name, file)
				})
				file = await zip.generateAsync({ type: "blob" })
			}

			await methods[method](record!._id, file);
		}

		return await push()
			.then(() => {
				setInformation(undefined)
				// setNewTargetedIrm(undefined)
				// __loadPatientRecord(record!._id)
			})
			.catch(err => {
				setInformation(undefined)
				setError({
					title: t('error'),
					message: err.message
				})
			})
			.finally(() => {
				setInformation(undefined)
			})
	}

	const __updateStatus = (status: PatientRecordStatus) => {
		if (status === PatientRecordStatus.GIVE_UP)
			return __onClickRecordReject()

		if (record && user) {
			PatientRecordRequests.updatePatientRecord({
				_id: record._id,
				status
			})
				.then(() => {
					setRecord({ ...record, status })
				}).catch((err: GraphQLError) => {
					setInformation(undefined)
					setError({
						title: t('error'),
						message: err.message
					})
				})
		}

	}
	const __onClickRemoveRecord = () => {
		setInformation({
			title: t('Deletion in progress'),
			showProgress: true
		})
		PatientRecordRequests.removePatientRecord(record!._id)
			.then(() => {
				history.push(PatientRecordsRouter.getRoute())
			}).catch((err: GraphQLError) => {
				setInformation(undefined)
				setError({
					title: t('error'),
					message: err.message
				})
			})

	}

	const __onClickDownloadTargeted = async (): Promise<void> => {
		if (
			user && [Roles.CLINICIAN].includes(user.role) &&
			record && !record.clinicianDowloadedDate
		) {
			try {
				const date = new Date()
				await PatientRecordRequests.updatePatientRecord({
					_id: record._id,
					clinicianDowloadedDate: date,
					status: PatientRecordStatus.DOWNLOADED
				})

				setRecord({ ...record, clinicianDowloadedDate: date, status: PatientRecordStatus.DOWNLOADED })
			} catch (err) {
				console.error(err)
			}
		}
		openInformativeSnackbar({ message: 'exp.RGPD data period availability', icon: <GppGoodIcon /> })
	}

	const __onClickDownloadOriginal = async (): Promise<void> => {
		if (
			user && [Roles.ADMIN, Roles.OPERATOR].includes(user.role)
			&& record
		) {
			try {
				await PatientRecordRequests.updatePatientRecord({
					_id: record._id,
					status: PatientRecordStatus.IN_PROGRESS
				})

				setRecord({ ...record, status: PatientRecordStatus.IN_PROGRESS })
			} catch (err) {
				console.error(err)
			}
		}
		openInformativeSnackbar({ message: 'exp.RGPD data period availability', icon: <GppGoodIcon /> })
	}

	const __onClickRecordReject = () => {
		setConfirmation({
			title: t('reject')
			, message: t('are you sure to reject the record ?')
			, placeholderReason: record?.giveUpReason
			, askReason: true
			, onClickAccept: (reason) => { setConfirmation(undefined); __onValidateRecordReject(reason) }
			, onClickCancel: () => setConfirmation(undefined)
		})
	}
	const __onValidateRecordReject = (reasons?: string | undefined) => {

		if (!reasons || reasons.length === 0)
			return setError({
				title: t('error'),
				message: t('thanks to give a reason'),
				onClose: () => setError(undefined)
			})

		setInformation({
			title: t('rejecting'),
			showProgress: true
		})
		PatientRecordRequests.rejectPatientRecord(record!._id, reasons)
			.then(() => {
				history.push(PatientRecordsRouter.getRoute())
			}).catch((err: GraphQLError) => {
				setInformation(undefined)
				setError({
					title: t('error'),
					message: err.message
				})
			})

	}

	const __onClickShowOriginalIrmFiles = () => {
		setInformation({
			title: t('loading'),
			showProgress: true
		})
		PatientRecordRequests.downloadOriginalIrmFileNames(record!._id)
			.then((files: Array<string>) => {
				setFileListProps({
					files: files,
					title: t('original irm files'),
					urlToDownload: `${process.env.REACT_APP_SERVER}/patient-record/original-irm/${record!._id}?log`
				})
				setInformation(undefined)
			}).catch((err: GraphQLError) => {
				setInformation(undefined)
				setError({
					title: t('error'),
					message: err.message
				})
			})
	}

	const __onClickShowPostOpIrmFiles = () => {
		setInformation({
			title: t('loading'),
			showProgress: true
		})
		PatientRecordRequests.downloadPostOpIrmFileNames(record!._id)
			.then((files: Array<string>) => {
				setFileListProps({
					files: files,
					title: t('Post-Operative MRI files'),
					urlToDownload: `${process.env.REACT_APP_SERVER}/patient-record/postop-original-irm/${record!._id}?log`
				})
				setInformation(undefined)
			}).catch((err: GraphQLError) => {
				setInformation(undefined)
				setError({
					title: t('error'),
					message: err.message
				})
			})
	}

	const __onClickShowTargetedIrmFiles = () => {

		setInformation({
			title: t('loading'),
			showProgress: true
		})
		PatientRecordRequests.downloadTargetedIrmFileNames(record!._id)
			.then((files: Array<string>) => {
				setFileListTargetedProps({
					files: files,
					title: t('targeted irm files'),
					urlToDownload: `${process.env.REACT_APP_SERVER}/patient-record/targeted-irm/${record!._id}?log`
				})
				setInformation(undefined)
			})
			.catch((err: GraphQLError) => {
				setInformation(undefined)
				setError({
					title: t('error'),
					message: err.message
				})
			})
	}

	const __onClickShowTargetedPostOpIrmFiles = () => {

		setInformation({
			title: t('loading'),
			showProgress: true
		})
		PatientRecordRequests.downloadTargetedPostOpIrmFileNames(record!._id)
			.then((files: Array<string>) => {
				setFileListTargetedProps({
					files: files,
					title: t('Post-Operative targeted irm files'),
					urlToDownload: `${process.env.REACT_APP_SERVER}/patient-record/postop-targeted-irm/${record!._id}?log`
				})
				setInformation(undefined)
			})
			.catch((err: GraphQLError) => {
				setInformation(undefined)
				setError({
					title: t('error'),
					message: err.message
				})
			})
	}
	const __onClickUpdateRecord = () => {
		history.push(UpdatePatientRecordRouter.getRoute(record?._id))
	}

	const __onClickSendEndOfPreOp = () => {
		setInformation({
			title: t('loading'),
			showProgress: true
		})
		PatientRecordRequests.sendEndOfProcessingMail(record!._id).then(() => {
			__loadPatientRecord(record!._id);
			setInformation({
				title: t('mail sent'),
				showProgress: false,
				message: t('end of processing mail has been sent'),
				onClose: () => setInformation(undefined)
			})
			
		}).catch((err: GraphQLError) => {
			setInformation(undefined)
			setError({
				title: t('error'),
				message: err.message
			})
		})
	}

	const __onClickSendEndOfPostOp = () => {
		setInformation({
			title: t('loading'),
			showProgress: true
		})
		PatientRecordRequests.sendEndOfPostOpProcessingMail(record!._id).then(() => {
			__loadPatientRecord(record!._id);
			setInformation({
				title: t('mail sent'),
				showProgress: false,
				message: t('end of processing mail has been sent'),
				onClose: () => setInformation(undefined)
			})
		}).catch((err: GraphQLError) => {
			setInformation(undefined)
			setError({
				title: t('error'),
				message: err.message
			})
		})
	}

	return (
		<Grid container justifyContent="center" alignItems="center">
			<Grid item sm={6} xs={11}>
				<Paper>
					<Box m={3} p={1}>
						<Grid
							spacing={2}
							container>
							<Grid item xs={12}>
								{__renderTitle()}
							</Grid>
							<Grid item xs={8} sm={4} component={Box}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								{__renderContent()}
							</Grid>
							<Grid item container xs={12} justifyContent="flex-end">
								<Grid item xs={8} sm={4} >
									<Divider />
								</Grid>
							</Grid>
							<Grid item xs={12}>
								{__renderPreOperativeMRI()}
							</Grid>
							<Grid item xs={12}>
								{__renderPreOpMRIProcess()}
							</Grid>
							<Grid item xs={8} sm={4} component={Box}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								{__renderPostOperativeMRI()}
							</Grid>
							<Grid item xs={12}>
								{__renderPostOpMRIProcess()}
							</Grid>
							<Grid item xs={8} sm={4} component={Box}>
								<Divider />
							</Grid>
						</Grid>
					</Box>
				</Paper>
			</Grid>
			<ErrorDialog error={error} onClose={() => setError(undefined)} />
			<InformativeDialog information={information} onClose={() => { }} />
			<ConfirmationDialog
				confirmation={confirmation}
				onClose={() => setConfirmation(undefined)} />
			<FileListDialog
				onClickDownload={__onClickDownloadOriginal}
				fileListProps={fileListProps}
				onClose={() => setFileListProps(undefined)} />
			<FileListDialog
				onClickDownload={__onClickDownloadTargeted}
				fileListProps={fileListTargetedProps}
				onClose={() => setFileListTargetedProps(undefined)} />
		</Grid>
	);
}

export default PatientRecordPage