import { Box, Button, Dialog, Grid, Theme, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'

const styles = ({palette, spacing}: Theme) => createStyles({
	circle: {
		display: "inline-block",
		backgroundColor: palette.primary.main,
		borderRadius: "50%",
		width: 5,
		height: 5,
	},

})

export interface FileListDialogProps {
	title?		:	string
	files?		:	string[]
	urlToDownload?	:	string
	onClose?		:	() => void; /** Function called when user whant close the dialog */
}

interface IProps extends WithStyles<typeof styles>, WithTranslation {
	fileListProps?: FileListDialogProps
	onClose?		:	() => void; /** Function called when user want close the dialog */
	onClickDownload?:	() => void | Promise<void>; /** Function called when the user click on the dowload button */
}


interface IState {
}

/**
 * Component used to render informative data
 */
class FileListDialog extends React.Component<IProps, IState> {

	render = () => {
		const classes = this.props.classes;
		const fileListProps = this.props.fileListProps

		return (
            <Dialog open={fileListProps !== undefined} onClose={this.__onClose}>
                <Box margin={2}>
                    <Grid container spacing={1}>
                        {
                            fileListProps?.title &&
                            <Grid item xs={12}>
                                <Typography align="center" variant="h5">{fileListProps?.title}</Typography>
                            </Grid>
                        }
                         {
                             fileListProps?.urlToDownload &&
                             <Grid item container xs={12} justifyContent="center">
                                 <Button
                                    //  href={fileListProps?.urlToDownload}
                                     variant="contained"
                                     color="secondary"
                                     onClick={this.__onClickDownload}
                                     startIcon={<CloudDownloadIcon />}>
                                     <Typography variant="caption">{this.props.t('download')}</Typography>
                                 </Button>
                             </Grid>
                         }
                         <Grid item xs={12}>
                         {
                             fileListProps?.files?.map((file, index) => {
                                 return (<Grid key={index} item xs={12} container spacing={2}>	
                                     <Grid item><Box className={classes.circle} /></Grid>
                                     <Grid item><Typography>{file}</Typography></Grid>
                                 </Grid>)
                             })
                         }
                         </Grid>
                             
                         
                    </Grid>
                </Box>
            </Dialog>
        );
	}

	__onClose = () => {
		if (this.props.fileListProps?.onClose)
			this.props.fileListProps?.onClose()

		if (this.props.onClose)
			this.props.onClose()
	}

    __onClickDownload = async () => {
        const {fileListProps, onClickDownload} = this.props
        if (onClickDownload) {
            await onClickDownload()
        }

        if (fileListProps && fileListProps.urlToDownload) {
            const link = document.createElement('a');
            link.href = fileListProps.urlToDownload;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

}

export default withTranslation() (withStyles(styles)(FileListDialog))