import { HospitalCenter } from "./hospitalCenter";
import { PatientRecord } from "./patientRecord";

export enum Roles {
	ADMIN = 'ADMIN',
	CLINICIAN = 'CLINICIAN',
	OPERATOR = 'OPERATOR',
}


export enum UserStatus {
	ENABLE = 'ENABLE',
	DISABLE = 'DISABLE',
}

export interface User {
	_id			:		string; /** Id of the user */

	firstname	:		string | null; /** User's firstname */

	lastname	:		string | null; /** User's lastname */

	email		:		string; /** User's email */

	role		:		Roles; /** User's role */

	status		:		UserStatus;

	/** FIELD RESOLVER */
	hospitalCenter	:	HospitalCenter | null;

	patientRecords	:	Array<PatientRecord>
}

export interface UserCreate {
	firstname?	:		string | null; /** User's firstname */

	lastname?	:		string | null; /** User's lastname */

	email		:		string; /** User's email */

	password	:		string; /** User's password */

	role		:		Roles; /** User's role */

	status?		:		UserStatus;

	idHospitalCenter?	:	number | null; /** Id of the hospital center to link with */
}

export interface UserUpdate {
	_id			:		string /** User's _id */;

	firstname?	:		string | null /** (optional) User's firstname */;

	lastname?	:		string | null /** (optional) User's lastname */;

	role?		:	Roles; /** (optional) User's role. Only administrator can use this field */
	
	idHospitalCenter?	:	number | null; /** Id of the hospital center to link with */

	status?		:		UserStatus;

}

export interface UserDelete {
	_id			:		string;
}

export interface Auth {

	email		:		string;

	password	:		string;
}