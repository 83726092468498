import { User } from "./user";

export enum PatientRecordStatus {
	TO_DO = "TO_DO", /** Patient record to process - PreOperative MRI to process */
	IN_PROGRESS = "IN_PROGRESS", /** Patient record in progress */
	PREOP_MRI_MARKED = "PREOP_MRI_MARKED", /** PreOpertive MRI Marked */
	POSTOP_TO_PROCESS = "POSTOP_TO_PROCESS", /** PostOperative MRI to process */
	DONE = "DONE", /** Patient record processed */
	GIVE_UP = "GIVE_UP", /** Patient record refused by REBRAIN */
	DOWNLOADED = "DOWNLOADED", /** Processed patient record downloaded by clinician */
}

export enum PatientRecordPathology {
	PARKINSON = "PARKINSON",
	ESSENTIAL_TREMOR = "ESSENTIAL_TREMOR"
}

export enum PatientRecordTarget {
	VIM = "VIM",
	STN = "STN"
}

export enum PatientRecordSide {
	BILATERAL = "BILATERAL",
	LEFT = "LEFT",
	RIGHT = "RIGHT"
}

export enum PatientRecordTypeOfSurgery {
	DBS = "DBS",
	HIFU = "HIFU",
	RADIOSURGERY = "RADIOSURGERY",
}

export interface PatientRecord {
	_id			:		string; /** Id of the PatientRecord */

	idClinician	:		string; /** Id of the clinician having the record */

	patientNumber	:	number; /** Patient number */
	firstname		:	string | null /** */
	lastname		:	string | null /** */

	pathologie		:	PatientRecordPathology | null /** */
	target			:	PatientRecordTarget | null /** */
	side			:	PatientRecordSide | null /** */
	typeOfSurgery	:	PatientRecordTypeOfSurgery | null /** */

	status			:	PatientRecordStatus
	giveUpReason	:	string | null /** */

	clinicianDowloadedDate:	Date | null; /** Date relating the moment the clinicial has downloaded the processed IRM */

	treatmentDate	:	Date | null
	creationDate	:	Date /** Date of creation */
	
	/** FIELD RESOLVER */
	clinician			:	User | null;

	hasNoTargetedIrm	:	boolean
	hasTargetedIrm		:	boolean

	hasPostOpIrm			: boolean;
	hasTargetedPostOpIrm: boolean;
}

export interface PatientRecordCreate {
	firstname?		:	string | null /** */
	
	lastname?		:	string | null /** */
	
	pathologie?		:	PatientRecordPathology | null /** */

	target?			:	PatientRecordTarget | null /** */
	side?			:	PatientRecordSide | null /** */
	typeOfSurgery?	:	PatientRecordTypeOfSurgery | null /** */
}

export interface PatientRecordUpdate {

	_id				:	string; /** Patient record ID */

	firstname?		:	string | null /** */
	
	lastname?		:	string | null /** */
	
	pathologie?		:	PatientRecordPathology | null /** */

	target?			:	PatientRecordTarget | null /** */
	side?			:	PatientRecordSide | null /** */
	typeOfSurgery?	:	PatientRecordTypeOfSurgery | null /** */

	status?			:	PatientRecordStatus

	clinicianDowloadedDate?:	Date | null; /** Date relating the moment the clinicial has downloaded the processed IRM */
}
