/**
 * Typography used in theme
 * @param {JsonObject} theme theme customization object
 */

import { TypographyOptions } from "@mui/material/styles/createTypography";

export const themeTypography = (): TypographyOptions => {
	return {
		fontFamily: [
			// '-apple-system',
			// 'BlinkMacSystemFont',
			// '"Segoe UI"',
			// 'Roboto',
			// '"Helvetica Neue"',
			// 'Arial',

			'Cairo',
			'Kufam',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',


		].join(','),
		h3: {
			fontFamily: 'Balsamiq Sans'
		},
		h5: {
			fontWeight: 'bold',
			fontSize: 27
		},
		h6: {
			fontWeight: 'bold',
			fontSize: 22
		},
		subtitle2: {
			fontWeight: 'bold',
			fontSize: 15
		},
		
	};
};
