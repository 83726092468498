import { DashboardRouter } from "../dashboard.router";

export class UpdateHospitalCenterRouter {
    
    static getRoute(idHospital?: number): string {
        if (idHospital !== undefined)
            return `${DashboardRouter.getRoute()}/update-hospital-center/${idHospital}`
        else
            return `${DashboardRouter.getRoute()}/update-hospital-center/:id`
    }

}