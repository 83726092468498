import { FetchPolicy, withApollo, WithApolloClient } from '@apollo/react-hoc'
import {
    Box,
    Container,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { GraphQLError } from 'graphql'
import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { UserRequests } from '../../../apollo'
import ErrorDialog, { IErrorInformation } from '../../../components/errorDialog'
import InformativeDialog, { IInformative } from '../../../components/informativeDialog'
import { User, UserStatus } from '../../../rebrain-irm-model'
import { UpdateUserRouter } from '../../../router/dashboard/updateUser/updateUsers.router'
import UserRow from './userRow'

const styles = (theme: Theme) => createStyles({
	table: {
		[theme.breakpoints.up('md')]: {
			maxHeight: '90vh'
		},
	},
})

interface IState {
	users: Array<User>

	error?: IErrorInformation;
	information?: IInformative;
}

interface IProps
	extends
	WithStyles<typeof styles>
	, RouteComponentProps
	, WithTranslation
	, WithApolloClient<{}>
{

}

class UsersPage extends React.Component<IProps, IState> {

	constructor(props: IProps) {
		super(props);
		this.state = {
			users: []
		}
	}

	componentDidMount = () => {
		this.__loadUser("no-cache")
	}

	render = () => {
		const classes = this.props.classes;

		return(
			<Container component={Box} mt={2}>
					<TableContainer component={Paper} className={classes.table}>
						<Table size="small" stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell align="left">{this.props.t('status')}</TableCell>
								<TableCell>{this.props.t('email')}</TableCell>
								{/* <TableCell align="right">{this.props.t('firstname')}</TableCell> */}
								<TableCell align="right">{this.props.t('name')}</TableCell>
								<TableCell align="right">{this.props.t('role')}</TableCell>
								<TableCell align="right">{this.props.t('hospital')}</TableCell>
								<TableCell align="right"/>
							</TableRow>
						</TableHead>
						<TableBody>

						{
							this.state.users.map((user: User, index) => {
								return (
									<UserRow
										key={index}
										onClickUpdateUser={this.__onClickUpdateUser}
										onClickSendResetPasswordMail={this.__onClickSendResetPasswordMail}
										onActivateUser={this.__onActivateUser}
										onDisableUser={this.__onDisableUser}
										onClickDeleteUser={this.__onClickDeleteUser}
										user={user}/>
								)
							})
						}
						</TableBody>
						</Table>
					</TableContainer>
				
				<ErrorDialog error={this.state.error} onClose={() => this.setState({error: undefined})}/>
				<InformativeDialog information={this.state.information} onClose={() => {}}/>
			</Container>
		)
	}

	__loadUser = (fetchPolicy?: FetchPolicy) => {
		this.setState({information: {
			title: this.props.t('loading'),
			showProgress: true
		}})

		UserRequests.users(fetchPolicy)
		.then((users: Array<User>) => {
			this.setState({information: undefined, users})
		})
		.catch((err: GraphQLError) => {
			this.setState({information: undefined, error: {
				title: this.props.t('error'),
				message: err.message
			}})
		})
	}

	__onClickUpdateUser = (user: User) => {
		this.props.history.push(UpdateUserRouter.getRoute(user._id))
	}

	__onClickSendResetPasswordMail = (user: User) => {
		this.setState({information: {
			showProgress: true,
			title: this.props.t('loading'),
		}})


		UserRequests.forgottenPassword(user.email)
		.then(() => {
			this.setState({information: {
				showProgress: false,
				message: this.props.t('mail to reset password has been sent'),
				onClose: () => this.setState({information: undefined})
			}})

		}).catch((e: GraphQLError) => {
			this.setState({ information: undefined,
				error: {
					title: this.props.t('error'),
					message: e.message
				}
			})

		})
	}

	__onClickDeleteUser = (user: User) => {
		this.setState({information: {
			showProgress: true,
			title: this.props.t('deleting'),
		}})


		UserRequests.deleteUser(user._id)
		.then(() => {
			this.setState({information: {
				title: this.props.t('User is deleted'),
				message: `User ${user.email} is deleted.`,
				showProgress: false,
				onClose: () => this.setState({information: undefined}, () => this.__loadUser("no-cache"))
			}})

		}).catch((e: GraphQLError) => {
			this.setState({ information: undefined,
				error: {
					title: this.props.t('error'),
					message: e.message
				}
			})

		})
	}

	__onActivateUser = (user: User) => {
		this.__setUserStatus(user, UserStatus.ENABLE)

	}
	__onDisableUser = (user: User) => {
		this.__setUserStatus(user, UserStatus.DISABLE)
	}

	__setUserStatus = (user: User, status: UserStatus) => {
		this.setState({information: {
			title: this.props.t('updating'),
			showProgress: true,
			message: `${this.props.t('updating')}...`
		}})
		UserRequests.updateUser({_id: user._id, status}).then(() => {
			this.__loadUser("no-cache")
		}).catch((err: GraphQLError) => {
			this.setState({information: undefined, error: {
				title: this.props.t('error'),
				message: err.message,
			}})
		})
	}

}



export default withTranslation() (withApollo(withRouter(withStyles(styles)(UsersPage)) as any))