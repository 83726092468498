// mui
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, ButtonGroup, ButtonGroupProps, Typography } from "@mui/material";
// hooks
import { useTranslation } from "react-i18next";
//

interface UploadButtonGroupProps extends ButtonGroupProps {
  onUpload: (files: Array<File>) => void;
}

export const UploadButtonGroup = ({ onUpload, ...props }: UploadButtonGroupProps) => {
  /** Hooks */
	const { t } = useTranslation()

  /** States */
  let _folderInput: HTMLInputElement | null = null;
  let _fileInput: HTMLInputElement | null = null;

  /** Methods */
  const setFileInput = (ref: HTMLInputElement | null) => {
    _fileInput = ref
    if (_fileInput) {
      _fileInput.type = "file"
    }
  }
  const setFolderInput = (ref: HTMLInputElement | null) => {
    _folderInput = ref
    if (_folderInput) {
      // Allow folder uploads
      _folderInput.webkitdirectory = true
      _folderInput.multiple = true
      _folderInput.type = "file"
    }
  }

  return (
    <ButtonGroup
      variant="contained" color="secondary"
      {...props}
    >
      <Button
        startIcon={<AddCircleIcon />}
        // component="span"
        onClick={() => _fileInput && _fileInput.click()}
      >
        <input
          ref={setFileInput}
          onChange={e => onUpload(Array.prototype.slice.call(e.target.files))}
          multiple
          type="file"
          hidden
        />
        <Typography variant="caption">{t('new file')}</Typography>
      </Button>
      <Button
        onClick={() => _folderInput && _folderInput.click()}
      >
        <input
          ref={setFolderInput}
          onChange={e => onUpload(Array.prototype.slice.call(e.target.files))}
          multiple
          type="file"
          hidden
        />
        <Typography variant="caption">{t('folder')}</Typography>
      </Button>
    </ButtonGroup>
  )
}