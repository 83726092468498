import { useContext } from 'react';
import SnackbarContext from '../contexts/SnackbarContext';

// import JWTContext from '../contexts/JWTContext';

//-----------------------|| AUTH HOOKS ||-----------------------//

const useSnackbar = () => useContext(SnackbarContext);

export default useSnackbar;
