import { withApollo, WithApolloClient } from '@apollo/react-hoc'
import { Grid, TextField, Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { GraphQLError } from 'graphql'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { HospitalCenterRequests } from '../../../apollo'
import CreateSomethingWrapper from '../../../components/createSomethingWrapper'
import ErrorDialog, { IErrorInformation } from '../../../components/errorDialog'
import InformativeDialog, { IInformative } from '../../../components/informativeDialog'
import { HospitalCenterUpdate } from '../../../rebrain-irm-model'
const styles = ({palette, spacing}: Theme) => createStyles({
	title: {
		fontWeight: 'bolder'
	},
	gradient: {

	}
})

interface IState {
	hospital?: HospitalCenterUpdate | undefined;
	fieldErrors?:	{[key: string]: string};

	error?: IErrorInformation;
	information?: IInformative;
}

interface IProps
	extends
	WithStyles<typeof styles>
	, RouteComponentProps<{id: string;}>
	, WithTranslation
	, WithApolloClient<{}>
{

}

class UpdateHospitalCenterPage extends React.Component<IProps, IState> {

	constructor(props: IProps) {
		super(props);
		this.state = {
		}
	}

	componentDidMount = () => {
		const id = Number(this.props.match.params.id)

		if (isNaN(id)) {
			this.setState({error: {
				title: "An error occurred with the url",
				message: "Please retry later",
				onClose: this.props.history.goBack
			}})
		} else {
			this.__loadOriginHospital(id)
		}
			
	}

	render = () => {

		return(
			
			<CreateSomethingWrapper
				title={this.props.t('update hospital center')}
				onPressCancel={this.__onPressCancel}
				onPressCreate={this.__onPressCreate}
				>
				{this.__renderForm()}
				<ErrorDialog error={this.state.error} onClose={() => this.setState({error: undefined})}/>
				<InformativeDialog information={this.state.information} onClose={() => {}}/>

			</CreateSomethingWrapper>
		)
	}

	__renderForm = () => {
		return (
            <form>
			<Grid
				item
				container
				spacing={2}
				justifyContent="space-between"
				alignItems="center">

					<Grid item xs={12} sm={6}>	
						<TextField
							error={this.state.fieldErrors?.name !== undefined}
							helperText={this.state.fieldErrors?.name}
							id="name"
							label={this.props.t('name')}
							InputLabelProps={{
								shrink: true,
							}}
							fullWidth
							variant="outlined"
							value={this.state.hospital?.name}
							onChange={(e) => this.__onChange("name", e.target.value)}
							/>
					</Grid>
					<Grid item xs={12} sm={6}/>

					<Grid item xs={12}>
						<TextField
							error={this.state.fieldErrors?.address !== undefined}
							helperText={this.state.fieldErrors?.address}
							multiline
							rows={3}
							id="address"
							label={this.props.t('address')}
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							value={this.state.hospital?.address}
							onChange={(e) => this.__onChange("address", e.target.value)}
							/>
					</Grid>

				<Grid item xs={1} sm={6}/>

			</Grid>
			</form>
        );
	}

	__onChange = (field: keyof HospitalCenterUpdate, value: any) => {
		const hospital = this.state.hospital;

		if (hospital) {
			(hospital[field] as any) = value
			this.setState({hospital})
		}
	}

	__onPressCancel = () => {
		this.props.history.goBack()
	}
	__onPressCreate = () => {
		const hospital = this.state.hospital
		const errors: {[key: string]: string} = {}

		if (!hospital)
			return
		if (!hospital.name || hospital.name.length <= 0)
			errors.name = this.props.t('thanks to set a valid name')
		if (!hospital.address || hospital.address.length <= 0)
			errors.address = this.props.t('thanks to set a valid address')
		if (Object.keys(errors).length > 0)
			return this.setState({fieldErrors: errors})

		this.setState({fieldErrors: undefined, information: {
			title: this.props.t('updating'),
			showProgress: true,
			message: `${this.props.t('updating')}...`
		}})
		
		HospitalCenterRequests.updateHospitalCenter(this.state.hospital!)
		.then((_) => {
			this.setState({
				information: {
					title: `${this.props.t('hospital updated')}.`,
					message: `${this.props.t('hospital have been created')} !`,
					onClose: () => this.props.history.goBack()
				},
			})
			// setTimeout(this.props.history.goBack, 1500) cela créait la redirection en trop
		}).catch((err: GraphQLError) => {
			this.setState({information: undefined, error:{
				title: this.props.t('error'),
				message: err.message
			}})
		})
	}

	__loadOriginHospital = async (idHospitalCenter: number) => {
		this.setState({information: {
			title: this.props.t('loading')
			, showProgress: true
		}})

		HospitalCenterRequests.hospitalCenter(idHospitalCenter, "no-cache")
		.then((hospital) => {
			this.setState({
				information: undefined,
				hospital
			})
		})
		.catch((err: GraphQLError) => {
			this.setState({information: undefined, error:{
				title: this.props.t('error while loading'),
				message: err.message
			}})
		})

	}
}



export default withTranslation() (withApollo(withRouter(withStyles(styles)(UpdateHospitalCenterPage)) as any))