import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
	Box,
	Button, FormControl,
	Grid,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Paper,
	Select,
	TextField,
	Theme
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { PatientRecord, PatientRecordPathology, PatientRecordSide, PatientRecordStatus, PatientRecordTarget, PatientRecordTypeOfSurgery } from '../../../rebrain-irm-model';
import FormatToolbox from '../../../toolbox/FormatToolbox';

const styles = ({ palette, spacing }: Theme) => createStyles({
})

interface PatientRecordFilters extends Partial<PatientRecord> {
	target?: PatientRecordTarget | undefined;
	side?: PatientRecordSide | undefined;
	typeOfSurgery?: PatientRecordTypeOfSurgery | undefined;
	pathologie?: PatientRecordPathology | undefined;

	status?: PatientRecordStatus | undefined;

}
interface Filters extends PatientRecordFilters {
	search?: string | undefined
}

interface IState {
	filters: Filters
}

interface IProps
	extends
	WithStyles<typeof styles>
	, WithTranslation {
	records: Array<PatientRecord>
	onApplyFilter: (records: Array<PatientRecord>) => void
}

class PatientRecordFiltersForm extends React.Component<IProps, IState> {

	constructor(props: IProps) {
		super(props);
		this.state = {
			filters: {}
		}
	}

	render = () => {
		return (
			<Paper>
				<Grid container component={Box} spacing={1} p={1}>
					{/* <Grid item xs={12}>
						<Typography variant="h5">Filters</Typography>
					</Grid> */}
					<Grid item xs={12} sm={6} lg={2}>
						<TextField
							size="small"
							variant="outlined"
							fullWidth
							onChange={(e) => this.__onChange("search", e.target.value)}
							label={`${this.props.t('search')} ...`}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={2}>
						<FormControl size="small" variant="outlined" fullWidth>
							<InputLabel shrink={true} htmlFor="status-native-simple">{this.props.t('status')}</InputLabel>
							<Select
								native
								variant="outlined"
								value={this.state.filters.status}
								onChange={(e) => this.__onChange("status", e.target.value)}
								inputProps={{ id: 'status-native-simple' }}
								input={<OutlinedInput notched label={this.props.t('status')} />}
							>
								<option value={undefined}>{undefined}</option>
								{
									Object.values(PatientRecordStatus).map((value) => (
										<option key={value} value={value}>{FormatToolbox.patientRecordEnumToString(value)}</option>
									))
								}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} lg={2}>
						<FormControl size="small" variant="outlined" fullWidth>
							<InputLabel shrink={true} htmlFor="pathologie-native-simple">{this.props.t('pathology')}</InputLabel>
							<Select
								native
								variant="outlined"
								value={this.state.filters.pathologie}
								onChange={(e) => this.__onChange("pathologie", e.target.value)}

								inputProps={{ id: 'pathologie-native-simple' }}
								input={<OutlinedInput notched label={this.props.t('pathology')} />}
							>
								<option value={undefined} />
								<option value={PatientRecordPathology.PARKINSON}>{this.props.t(PatientRecordPathology.PARKINSON)}</option>
								<option value={PatientRecordPathology.ESSENTIAL_TREMOR}>{this.props.t(PatientRecordPathology.ESSENTIAL_TREMOR)}</option>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} lg={2}>
						<FormControl size="small" variant="outlined" fullWidth>
							<InputLabel shrink={true} htmlFor="target-native-simple">{this.props.t('target')}</InputLabel>
							<Select
								native
								variant="outlined"
								value={this.state.filters.target}
								onChange={(e) => this.__onChange("target", e.target.value)}

								inputProps={{ id: 'target-native-simple' }}
								input={<OutlinedInput notched label={this.props.t('target')} />}
							>
								<option value={undefined}>{undefined}</option>
								<option value={PatientRecordTarget.STN}>{this.props.t(PatientRecordTarget.STN)}</option>
								<option value={PatientRecordTarget.VIM}>{this.props.t(PatientRecordTarget.VIM)}</option>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} lg={2}>
						<FormControl size="small" variant="outlined" fullWidth>
							<InputLabel shrink={true} htmlFor="side-native-simple">{this.props.t('side')}</InputLabel>
							<Select
								native
								variant="outlined"
								value={this.state.filters.side}
								onChange={(e) => this.__onChange("side", e.target.value)}

								inputProps={{ id: 'side-native-simple' }}
								input={<OutlinedInput notched label={this.props.t('side')} />}
							>
								<option value={undefined}>{undefined}</option>
								<option value={PatientRecordSide.BILATERAL}>{this.props.t(PatientRecordSide.BILATERAL)}</option>
								<option value={PatientRecordSide.LEFT}>{this.props.t(PatientRecordSide.LEFT)}</option>
								<option value={PatientRecordSide.RIGHT}>{this.props.t(PatientRecordSide.RIGHT)}</option>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} lg={2}>
						<FormControl size="small" variant="outlined" fullWidth>
							<InputLabel shrink={true} htmlFor="typeOfSurgery-native-simple">{this.props.t('typeOfSurgery')}</InputLabel>
							<Select
								native
								variant="outlined"
								value={this.state.filters.typeOfSurgery}
								onChange={(e) => this.__onChange("typeOfSurgery", e.target.value)}

								inputProps={{ id: 'typeOfSurgery-native-simple' }}
								input={<OutlinedInput notched label={this.props.t('type of surgery')} />}
							>
								<option value={undefined}>{undefined}</option>
								<option value={PatientRecordTypeOfSurgery.DBS}>{this.props.t(PatientRecordTypeOfSurgery.DBS)}</option>
								<option value={PatientRecordTypeOfSurgery.HIFU}>{this.props.t(PatientRecordTypeOfSurgery.HIFU)}</option>
								<option value={PatientRecordTypeOfSurgery.RADIOSURGERY}>{this.props.t(PatientRecordTypeOfSurgery.RADIOSURGERY)}</option>
							</Select>
						</FormControl>
					</Grid>
					{/* <Grid container justifyContent="flex-end"
						 item xs={12} sm={6} lg={2}>
						<Grid item xs={8} sm={4} >
							<Divider />
						</Grid>
					</Grid> */}
					<Grid
						item
						container
						xs
						spacing={2}
						justifyContent="flex-end">
						<Grid item>
							<Button
								onClick={this.__onPressReset}
								variant="contained"
								color="error">
								<CloseIcon />
							</Button>
						</Grid>
						<Grid item>
							<Button
								onClick={this.__onPressApply}
								variant="contained"
								color="secondary">
								<ArrowForwardIcon />
							</Button>
						</Grid>
					</Grid>
				</Grid>

			</Paper>
		);
	}

	__onPressApply = () => {
		const records = this.props.records.filter((record: PatientRecord) => {
			for (const [key, value] of Object.entries(this.state.filters as PatientRecordFilters)) {
				const k: keyof PatientRecordFilters = (key as keyof PatientRecordFilters);
				if (
					value !== undefined
					&& (value as string).length > 0
					&& record.hasOwnProperty(key)
					&& record[k] !== value
				)
					return false
			}
			const search = this.state.filters.search;
			if (
				search
			) {
				const words = search.toLowerCase().split(' ').filter(word => Boolean(word.length));
				const stringified = JSON.stringify(record).toLowerCase();

				console.debug(words.map(word => stringified.includes(word)))
				if (
					// Check all words exist
					!words.map(word => stringified.includes(word)).reduce((a, b) => a && b, true)
				)
					return false
			}

			return true
		})
		this.props.onApplyFilter(records)
	}

	__onPressReset = () => {
		const filters: Filters = {
			target: ("" as PatientRecordTarget),
			side: ("" as PatientRecordSide),
			typeOfSurgery: ("" as PatientRecordTypeOfSurgery),
			pathologie: ("" as PatientRecordPathology),
			search: "",
			status: ("" as PatientRecordStatus),
		}
		this.setState({ filters }, this.__onPressApply)
	}

	__onChange = (field: keyof Filters, value: any) => {
		const filters = this.state.filters;

		if (typeof value === "string" && value.length === 0)
			filters[field] = undefined
		else
			(filters[field] as any) = value

		this.setState({ filters })
	}

}



export default withTranslation()(withStyles(styles)(PatientRecordFiltersForm))